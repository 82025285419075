import { SingleSelect } from "../../../../../../components/SingleSelect";
import { useState, useCallback, ReactNode } from "react";
import { Row, Table } from "antd";
import { useFetchYears } from "./hooks/useFetchYears";
import { useFetchData } from "./hooks/useFetchData";
import { useTableColumns } from "./hooks/useTableColumns";
import { SummaryRow } from "./components/SummaryRow";
import { BalanceLabel } from "./components/BalanceLabel";

type PaymentHistoryProps = {
  accountNumber: string;
};

export const PaymentHistory = ({ accountNumber }: PaymentHistoryProps) => {
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  const onChangeDate = useCallback((value: any) => setSelectedYear(value), []);

  const { options, isLoading: isYearsLoading } = useFetchYears(
    accountNumber,
    setSelectedYear
  );

  const { data, isLoading } = useFetchData(accountNumber, selectedYear);

  const { columns } = useTableColumns(
    selectedYear,
    <BalanceLabel data={data} />
  );

  const summaryRow = useCallback((): ReactNode => {
    return data.total ? <SummaryRow data={data} /> : <></>;
  }, [data]);

  return (
    <div className="table-page-content history-table-content px-8">
      <div className="pt-6">
        <SingleSelect
          options={options}
          label="Year"
          value={selectedYear}
          onChange={onChangeDate}
          loading={isYearsLoading}
        />
        <p className="my-0 pt-4 text-sm">
          Please be advised that it may take up to 24 hours to process the
          transaction. All payments will be posted as of the payment effective
          date and not the payment initiation date.
        </p>
      </div>
      <Row className="d-block table__container mt-4">
        <Table
          dataSource={data.transactions}
          columns={columns}
          pagination={false}
          loading={isLoading}
          summary={summaryRow}
          className="overflow-y-auto"
          rowKey={"id"}
        />

        <p className="my-0 pt-4 text-sm title">
          Disclaimer: This balance and/or transaction history is provided for
          information purposes only and should not be used for payoff purposes.
        </p>
      </Row>
    </div>
  );
};
