import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import { useFormData } from "./useFormData";
import BackGroundService from "../../../services/background";
import { useAppDispatch } from "../../../store";
import { sendLetter } from "../../../acions/accounts/mailings";
import { setErrorMessage } from "../../../acions/actionsCreators/messageActions";
import { useDownloadReport } from "../../../lib/hooks/useDownloadReport";
import MailingsService from "../../../services/accounts/mailings";
import { updateMailingKey } from "../../../acions/actionsCreators/mailingKeyActions";

export const useSave = (
  contractId: string,
  form: FormInstance,
  sendToData: any[],
  close: () => void
) => {
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { download } = useDownloadReport(setIsSaving);

  const { getFormData } = useFormData(sendToData, contractId);

  const onError = useCallback(
    (interval?: any) => {
      setIsSaving(false);
      dispatch(setErrorMessage("Generating Letter was failed", true));
      interval && clearInterval(interval);
    },
    [dispatch]
  );

  const onSave = useCallback(async () => {
    try {
      const req = await form.validateFields();

      setIsSaving(true);

      const formData = getFormData(req);

      const id: any = await Promise.resolve(dispatch(sendLetter(formData)));

      const interval = setInterval(() => {
        BackGroundService.getProgress(id)
          .then((res: any) => {
            const { status, result } = res;

            if (status === 4) {
              if (result.failedCount === 0) {
                const { additionalData } = result;

                if (additionalData && additionalData.AttachmentId) {
                  download(MailingsService, additionalData.AttachmentId);
                }

                dispatch(updateMailingKey());

                setIsSaving(false);
                close();
                clearInterval(interval);
              } else {
                onError(interval);
              }
            }

            status === 6 && onError(interval);
          })
          .catch(() => onError(interval));
      }, 1000);
    } catch {
      setIsSaving(false);
    }
  }, [close, dispatch, download, form, getFormData, onError]);

  return { isSaving, onSave, setIsSaving };
};
