import { Form, FormInstance } from "antd";
import { useCallback, useEffect } from "react";
import ReportService from "../../../../../services/reportGeneration";
import BackGroundService from "../../../../../services/background";
import { DOCX_TYPE } from "../../../../../lib/constants";

export const useGenerateAttachment = (
  form: FormInstance,
  contractId: string,
  setGlobalIsLoading: (value: boolean) => void
) => {
  const templateId = Form.useWatch("templateId", form);

  const setError = useCallback(
    (value: string[]) => {
      form.setFields([
        {
          name: "templateId",
          errors: value,
        },
      ]);
    },
    [form]
  );

  const onFail = useCallback(
    (interval: any) => {
      setGlobalIsLoading(false);

      clearInterval(interval);

      setError(["Generation failed"]);
    },
    [setError, setGlobalIsLoading]
  );

  const generate = useCallback(async () => {
    if (templateId) {
      setError([]);
      setGlobalIsLoading(true);

      const reportId: string = await Promise.resolve(
        ReportService.generateEmailLetterReport(templateId, contractId)
      );

      const interval = setInterval(() => {
        BackGroundService.getProgress(reportId)
          .then((res: any) => {
            const { status, result } = res;

            if (status === 4) {
              if (result.succesfulCount === 1) {
                clearInterval(interval);

                setGlobalIsLoading(false);

                const value = {
                  templateId: result.additionalData.ResultFileItemId,
                  name: result.additionalData.ResultFileName,
                  type: DOCX_TYPE,
                  status: "done",
                  size: result.additionalData.ResultFileSizeBytes,
                  taskId: reportId,
                  id: reportId,
                };

                form.setFieldValue("fileList", [value]);
              } else {
                setGlobalIsLoading(false);

                clearInterval(interval);

                setError(["Generation failed"]);
              }
            }

            status === 6 && onFail(interval);
          })
          .catch(() => onFail(interval));
      }, 1000);
    }
  }, [templateId, setError, setGlobalIsLoading, contractId, form, onFail]);

  useEffect(() => {
    generate();
  }, [generate]);
};
