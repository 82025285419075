import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import MailingsService from "../../services/accounts/mailings";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import {
  DELETE_USER_SUCCESS,
  MAIL_SETTINGS_WAS_SAVED,
} from "../../lib/constants";

export const saveSettings =
  (
    request: any,
    id: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await MailingsService.saveSettings(request, id);

      dispatch(setSuccessMessage(MAIL_SETTINGS_WAS_SAVED));

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));
      return await Promise.reject(error);
    }
  };

export const deleteLetter =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await MailingsService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const sendLetter =
  (req: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await MailingsService.sendLetter(req);

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
