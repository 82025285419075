import { API_URL } from "../configs/apiUrl";
import authAxios, { authHeader } from "./data";

const generateBatchReport = async (request: any) => {
  const result = await authAxios.post(
    API_URL + "ReportGeneration/BatchReport",
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const generateChecksReport = async (request: any) => {
  const result = await authAxios.post(
    API_URL + "ReportGeneration/ChecksReport",
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const generateEmailLetterReport = async (
  templateId: string,
  contractId: string
) => {
  const result = await authAxios.post(
    `${API_URL}ReportGeneration/EmailLetterReport/${templateId}`,
    { contractId },
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const generateEnvelopeReport = async (request: any) => {
  const result = await authAxios.post(
    API_URL + "ReportGeneration/Envelope",
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const generateDisbursementsReport = async (request: any) => {
  const result = await authAxios.post(
    API_URL + "ReportGeneration/DisbursementReport",
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const generateTotalsReport = async (request: any) => {
  const result = await authAxios.post(
    API_URL + "ReportGeneration/TotalsReport",
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const download = async (id: string) => {
  const response = await authAxios.get(
    `${API_URL}ReportGeneration/${id}/Download`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
  return response.data;
};

const ReportService = {
  generateBatchReport,
  generateChecksReport,
  generateEmailLetterReport,
  generateEnvelopeReport,
  generateDisbursementsReport,
  generateTotalsReport,
  download,
};

export default ReportService;
