import { useCallback } from "react";

export const useDownloadReport = (
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const download = useCallback(
    async (service: any, id?: string) => {
      try {
        const res = await service.download(id);

        setIsDownloading(false);
        const blob = new Blob([res], {
          type: "application/pdf",
        });
        const _url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = _url;
        link.target = "_blank";
        link.style.display = "none";
        link.click();
        link.remove();
      } catch {
        setIsDownloading(false);
      }
    },
    [setIsDownloading]
  );

  return { download };
};
