import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { ReactNode, useCallback, useMemo } from "react";
import { DATE_FORMAT } from "../../../../../../../lib/constants";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";

export const useTableColumns = (
  date: number | null,
  balanceLabel: ReactNode
) => {
  const getFormattedDate = useCallback(
    (value: string) => dayjs(value).format(DATE_FORMAT),
    []
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Date",
        children: [
          {
            title: date,
            dataIndex: "date",
            key: "date",
            render: (value: string) => getFormattedDate(value),
            width: "10%",
          },
        ],
      },
      {
        title: "Description",
        children: [
          {
            title: "",
            dataIndex: "description",
            key: "description",
            width: "16%",
          },
        ],
      },
      {
        title: "Amount",
        align: "right",
        children: [
          {
            title: "",
            dataIndex: "amount",
            key: "amount",
            render: (value: number) => getFormattedCurrency(value),
            align: "right",
            width: "9%",
          },
        ],
      },
      {
        title: "Int. Paid To Date",
        align: "right",
        children: [
          {
            title: "",
            dataIndex: "interestPaidToDate",
            key: "interestPaidToDate",
            render: (value: string) => getFormattedDate(value),
            align: "right",
            width: "12%",
          },
        ],
      },
      {
        title: "Principal Paid",
        align: "right",
        children: [
          {
            title: "",
            dataIndex: "principalPaid",
            key: "principalPaid",
            render: (value: number) => getFormattedCurrency(value),
            width: "11%",
            align: "right",
          },
        ],
      },
      {
        title: "Interest Paid",
        align: "right",
        children: [
          {
            title: "",
            dataIndex: "interestPaid",
            key: "interestPaid",
            render: (value: number) => getFormattedCurrency(value),
            align: "right",
            width: "10%",
          },
        ],
      },
      {
        title: "Fees",
        align: "right",
        children: [
          {
            title: "",
            dataIndex: "fee",
            key: "fee",
            render: (value: number) => getFormattedCurrency(value),
            align: "right",
            width: "8%",
          },
        ],
      },
      {
        title: "Payment Impound",
        align: "right",
        children: [
          {
            title: "",
            dataIndex: "paymentImpound",
            key: "paymentImpound",
            render: (value: number) => getFormattedCurrency(value),
            align: "right",
            width: "13%",
          },
        ],
      },
      {
        title: "Ending Balance",
        align: "right",
        children: [
          {
            title: balanceLabel,
            dataIndex: "endingBalance",
            key: "endingBalance",
            render: (value: number) => getFormattedCurrency(value),
            align: "right",
            width: "11%",
          },
        ],
      },
    ],
    [balanceLabel, date, getFormattedDate]
  );

  return { columns };
};
