import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";

type BalanceLabelProps = {
  data: any;
};

export const BalanceLabel = ({ data }: BalanceLabelProps) => {
  return (
    <div className="balance-value text-md">
      <span className="label">Balance at the beginning of the year:</span>
      <span className="pl-2">
        {getFormattedCurrency(data.balanceBeginOfYear)}
      </span>
    </div>
  );
};
