import { Form, FormInstance, Row } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useFetchPrimaryData } from "../hooks/useFetchPrimaryData";
import { SendToFormItem } from "./SendToFormItem";
import { SubjectRow } from "./SubjectRow";
import { Message } from "./Message";
import { Attachments } from "./Attachments";
import { useSend } from "../hooks/useSend";
import { useOnFail } from "../hooks/useOnFail";
import { Loader } from "./Loader";
import { IPrimaryData } from "../../../types/PrimaryData";

type FormContainerProps = {
  contractId: string;
  form: FormInstance;
  primaryData: IPrimaryData;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<any | null>>;
  close: () => void;
};

export const FormContainer = ({
  contractId,
  form,
  primaryData,
  isLoading,
  setIsLoading,
  setError,
  close,
}: FormContainerProps) => {
  const [sendToData, setSendToData] = useState<any[]>([]);

  const [isTemplateGenerating, setIsTemplateGenerating] =
    useState<boolean>(false);

  const setGlobalIsLoading = useCallback(
    (value: boolean) => {
      setIsTemplateGenerating(value);
      setIsLoading(value);
    },
    [setIsLoading]
  );

  const { onFail, clearError } = useOnFail(setError);

  const { send } = useSend(contractId, sendToData, setIsLoading, close, onFail);

  const { formattedData: initalData } = useFetchPrimaryData(
    primaryData,
    setIsLoading
  );

  const initialValues = useMemo(
    () => ({
      subject: "Important documents from Sunwest Escrow",
      body: "Hello!\n\nPlease see the attached notice regarding your Account.\n\n{Sunwest Logo}\nBest regards,\nSunwest Escrow Team",
    }),
    []
  );

  return (
    <Row className="d-block position-relative">
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        disabled={isLoading}
        initialValues={initialValues}
        onFinish={send}
        onChange={clearError}
        className={isTemplateGenerating ? "blured" : ""}
      >
        <SendToFormItem
          form={form}
          initalData={initalData}
          optionsData={sendToData}
          setOptionsData={setSendToData}
          clearError={clearError}
        />

        <SubjectRow
          form={form}
          contractId={contractId}
          isLoading={isLoading}
          clearError={clearError}
          setGlobalIsLoading={setGlobalIsLoading}
        />

        <Message />

        <Attachments clearError={clearError} />
      </Form>
      {isTemplateGenerating && <Loader />}
    </Row>
  );
};
