import { FormInstance } from "antd";
import { useDownloadReport } from "../../../../../lib/hooks/useDownloadReport";
import { useOpenProgressModal } from "../../../../../lib/hooks/useOpenProgressModal";
import ReportService from "../../../../../services/reportGeneration";
import { useCallback } from "react";

export const useDisbursementsReport = (
  form: FormInstance,
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>,
  onCancelClick: () => void
) => {
  const { download } = useDownloadReport(setIsDownloading);

  const { openModal } = useOpenProgressModal(
    "Generating Disbursements Report",
    "Generating Disbursements Report failed",
    (id?: string) => download(ReportService, id)
  );

  const printDisbursementsReport = useCallback(async () => {
    try {
      const res = await form.validateFields();

      setIsDownloading(true);

      const request: any = {
        dateFrom: res.dates[0].format(),
        dateTo: res.dates[1].format(),
      };

      if (res.batchType !== 0) {
        request.batchType = res.batchType;
      }

      const id: string = await ReportService.generateDisbursementsReport(
        request
      );

      openModal(id);

      onCancelClick();
    } catch {
      setIsDownloading(false);
    }
  }, [form, onCancelClick, openModal, setIsDownloading]);

  return { printDisbursementsReport };
};
