import { useCallback } from "react";
import { useFormData } from "./useFormData";
import { useAppDispatch } from "../../../../../../../../../store";
import BackGroundService from "../../../../../../../../../services/background";
import MailingsService from "../../../../../../../../../services/accounts/mailings";
import { EMAILS_WERE_SENT } from "../../../../../../../../../lib/constants";
import { setSuccessMessage } from "../../../../../../../../../acions/actionsCreators/messageActions";

export const useSend = (
  contractId: string,
  sendToData: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  close: () => void,
  onFail: (err: any) => void
) => {
  const dispatch = useAppDispatch();

  const { getFormData } = useFormData(sendToData, contractId);

  const onError = useCallback(
    (interval: any, err: any) => {
      setIsLoading(false);
      clearInterval(interval);
      onFail(err);
    },
    [onFail, setIsLoading]
  );

  const send = useCallback(
    async (values: any) => {
      try {
        setIsLoading(true);
        const formData = getFormData(values);

        const id: any = await Promise.resolve(
          MailingsService.sendEmail(formData)
        );

        const interval = setInterval(() => {
          BackGroundService.getProgress(id)
            .then((res: any) => {
              const { status, result } = res;

              if (status === 4) {
                if (result.failedCount === 0) {
                  setIsLoading(false);
                  close();
                  dispatch(setSuccessMessage(EMAILS_WERE_SENT));
                  clearInterval(interval);
                } else {
                  onError(interval, result);
                }
              }

              status === 6 &&
                onError(interval, {
                  response: {
                    data: {
                      title: "Sending was failed",
                    },
                  },
                });
            })
            .catch((e) => {
              onError(interval, e);
            });
        }, 1000);
      } catch (err) {
        setIsLoading(false);
        onFail(err);
      }
    },
    [setIsLoading, getFormData, close, dispatch, onError, onFail]
  );

  return { send };
};
