import { Button, Row } from "antd";

type FormButtonsProps = {
  close: () => void;
  onSave: () => void;
};

export const FormButtons = ({ close, onSave }: FormButtonsProps) => {
  return (
    <Row className="w-100 justify-end pa-4">
      <Button type="primary" shape="round" onClick={onSave}>
        Print
      </Button>
      <Button shape="round" className="ml-2" onClick={close}>
        Cancel
      </Button>
    </Row>
  );
};
