import { Button, Form, FormInstance, Row } from "antd";
import { useMemo } from "react";

type FooterButtonsProps = {
  form: FormInstance;
  isLoading: boolean;
  disabled: boolean;
  close: () => void;
  onSave: () => void;
};

export const FooterButtons = ({
  form,
  isLoading,
  disabled,
  close,
  onSave,
}: FooterButtonsProps) => {
  const fileList = Form.useWatch("fileList", form);

  const hasFiles = useMemo(() => {
    return fileList && fileList.length > 0;
  }, [fileList]);

  return (
    <Row className="w-100 justify-end mt-6">
      {!disabled && (
        <Button
          type="primary"
          shape="round"
          disabled={isLoading || !hasFiles}
          loading={isLoading}
          onClick={onSave}
        >
          Print
        </Button>
      )}
      <Button
        shape="round"
        type={disabled ? "primary" : "default"}
        className="ml-2"
        disabled={isLoading}
        onClick={close}
      >
        {disabled ? "Close" : "Cancel"}
      </Button>
    </Row>
  );
};
