import { FormInstance } from "antd";
import { useCallback } from "react";

export const usePrint = (form: FormInstance, close: () => void) => {
  const onSave = useCallback(async () => {
    try {
      await form.validateFields();

      close();
    } catch {}
  }, [form, close]);

  return { onSave };
};
