import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";

const fetchAll = async (request: any) => {
  const response = await authAxios.get(`${API_URL}ContractMailings`, {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (request: any) => {
  const response = await authAxios.get(`${API_URL}ContractMailings/Count`, {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const get = async (id: string, _: number) => {
  const result = await authAxios.get(`${API_URL}ContractMailings/${id}`, {
    headers: authHeader(),
  });

  return result.data;
};

const saveSettings = async (request: any, id: string) => {
  const result = await authAxios.put(
    `${API_URL}Contracts/${id}/mailing`,
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const sendEmail = async (request: any) => {
  const response = await authAxios.post(
    API_URL + "DomainEmails/outbox/send",
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const sendLetter = async (request: any) => {
  const response = await authAxios.post(
    API_URL + "ContractMailings/send",
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(`${API_URL}ContractMailings/${id}`, {
    headers: authHeader(),
  });

  return response.data;
};

const download = async (id: string) => {
  const response = await authAxios.get(
    `${API_URL}ContractMailings/attachments/${id}/download`,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      headers: authHeader(),
      responseType: "blob",
    }
  );

  return response.data;
};

const MailingsService = {
  fetchAll,
  fetchCount,
  get,
  saveSettings,
  sendEmail,
  sendLetter,
  download,
  remove,
};

export default MailingsService;
