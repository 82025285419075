import { useEffect, useState } from "react";
import AccountsService from "../../../../../../../services/accounts";

export const useFetchData = (
  contractNumber: string,
  selectedYear: number | null
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [data, setData] = useState<any>({});

  useEffect(() => {
    if (selectedYear) {
      setIsLoading(true);

      AccountsService.fetchAccountContractsHistory(contractNumber, selectedYear)
        .then((res) => {
          setIsLoading(false);

          if (res) {
            const transactions = res.transactions.map(
              (item: any, i: number) => ({
                ...item,
                id: i,
              })
            );
            setData({ ...res, transactions });
          }
        })
        .catch(() => setIsLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractNumber, selectedYear]);

  return { data, isLoading };
};
