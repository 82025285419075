import { useCallback, useMemo } from "react";
import { LetterModal } from "../../../../../../../components/LetterModal";
import { EmailModal } from "../../../../../../../components/EmailModal";

type ModalsProps = {
  contractId: string;
  itemToOpen: { id: string; type: 1 | 2 } | null;
  setItemToOpen: React.Dispatch<
    React.SetStateAction<{ id: string; type: 1 | 2 } | null>
  >;
};

export const Modals = ({
  contractId,
  itemToOpen,
  setItemToOpen,
}: ModalsProps) => {
  const close = useCallback(() => setItemToOpen(null), [setItemToOpen]);

  const shouldEmailOpen: boolean = useMemo(
    () => (itemToOpen ? itemToOpen.type === 1 : false),
    [itemToOpen]
  );

  const shouldLetterOpen: boolean = useMemo(
    () => (itemToOpen ? itemToOpen.type === 2 : false),
    [itemToOpen]
  );

  return (
    <>
      <EmailModal
        id={itemToOpen ? itemToOpen.id : null}
        close={close}
        sourceType={0}
        shouldOpen={shouldEmailOpen}
      />
      <LetterModal
        id={itemToOpen?.id}
        contractId={contractId}
        primaryData={undefined}
        close={close}
        shouldOpen={shouldLetterOpen}
      />
    </>
  );
};
