import { Form, Row } from "antd";
import { Header } from "./Header";
import { Body } from "./Body";
import { useCallback, useState } from "react";
import { useSave } from "../hooks/useSave";
import { PaymentManualUpdated } from "../../../types/PaymentManualUpdated";
import "../styles/index.scss";
import "../styles/disabled-form-items.scss";

type CreatePaymentProps = {
  paymentId: string;
  batchId: string;
};

export const CreatePayment = ({ paymentId, batchId }: CreatePaymentProps) => {
  const [form] = Form.useForm();

  const [isFormBlured, setIsFormBlured] = useState<boolean>(true);

  const [instance, setInstance] = useState<any>(null);

  const [manualUpdated, setManualUpdated] = useState<PaymentManualUpdated>({
    principalAmount: false,
    interestAmount: false,
    disbursements: false,
    lateCharge: false,
    fee: false,
  });

  const { save, isLoading, setIsLoading, post, update } = useSave(
    instance,
    form,
    manualUpdated
  );

  const backToNew = useCallback((): void => {
    setIsFormBlured(true);
    form.resetFields();
    setManualUpdated({
      principalAmount: false,
      interestAmount: false,
      disbursements: false,
      lateCharge: false,
      fee: false,
    });
    setInstance(null);
  }, [form]);

  return (
    <Row className="d-block create-payment">
      <Header
        paymentId={paymentId}
        isFormBlured={isFormBlured}
        isLoading={isLoading}
        save={save}
        post={post}
        backToNew={backToNew}
        instance={instance}
        setIsLoading={setIsLoading}
      />
      <Body
        paymentId={paymentId}
        batchId={batchId}
        isFormBlured={isFormBlured}
        setIsFormBlured={setIsFormBlured}
        instance={instance}
        form={form}
        manualUpdated={manualUpdated}
        isLoading={isLoading}
        update={update}
        setInstance={setInstance}
        setIsLoading={setIsLoading}
        setManualUpdated={setManualUpdated}
      />
    </Row>
  );
};
