import { Form, Input, Row, Col, Select } from "antd";
import { useFetchTemplates } from "../../../pages/Account/components/View/Admin/HeaderActions/components/EmailsModal/components/SubjectRow/hooks/useFetchTemplates";

type SubjectRowProps = {
  sourceType: 0 | 1 | 2;
};

export const SubjectRow = ({ sourceType }: SubjectRowProps) => {
  const { isLoading, formatted } = useFetchTemplates(sourceType === 1);

  return (
    <>
      {sourceType !== 1 ? (
        <Form.Item label="Email subject" name="subject" className="w-100">
          <Input disabled />
        </Form.Item>
      ) : (
        <Row className="w-100">
          <Col span={15} className="pr-2">
            <Form.Item label="Email subject" name="subject" className="w-100">
              <Input />
            </Form.Item>
          </Col>
          <Col span={9} className="pr-2">
            <Form.Item className="mb-4 mt-6" name="templateId">
              <Select
                options={formatted}
                disabled={isLoading}
                placeholder="Insert from Template"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};
