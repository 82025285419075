import authAxios, { authHeader } from "../data";
import qs from "qs";
import { API_URL } from "../../configs/apiUrl";

const fetchAccounts = async (request: any) => {
  const response = await authAxios.get(API_URL + "Accesses", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetchAccountsCount = async (request: any) => {
  const response = await authAxios.get(API_URL + "Accesses/count", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetchAccountContracts = async (contractNumber: string) => {
  const response = await authAxios.get(
    `${API_URL}Accesses/contracts/${contractNumber}`,
    { headers: authHeader() }
  );

  return response.data;
};

const fetchAccountContractsHistory = async (
  contractNumber: string,
  selectedYear: number | null
) => {
  const yearQuery = selectedYear ? `?year=${selectedYear}` : "";

  const response = await authAxios.get(
    `${API_URL}Accesses/contracts/${contractNumber}/history${yearQuery}`,
    { headers: authHeader() }
  );

  return response.data;
};

const fetchYears = async (contractNumber: string) => {
  const result = await authAxios.get(
    `${API_URL}Accesses/contracts/${contractNumber}/years`,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const getLastUpdate = async () => {
  const response = await authAxios.get(API_URL + "info/last_update", {
    headers: authHeader(),
  });

  return response.data;
};

const AccountsService = {
  fetchAccounts,
  fetchAccountsCount,
  fetchAccountContracts,
  fetchAccountContractsHistory,
  getLastUpdate,
  fetchYears,
};

export default AccountsService;
