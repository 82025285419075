import { useEffect, useMemo, useState } from "react";
import {
  TemplateCategoryItem,
  TemplateItem,
} from "../../../../../../../../../../../types/Template";
import TemplatesService from "../../../../../../../../../../../services/administration/templates";

export const useFetchTemplates = (shouldFetch: boolean = true) => {
  const [data, setData] = useState<TemplateItem[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    shouldFetch &&
      TemplatesService.fetchAll()
        .then((res) => {
          if (res.length > 0) {
            const otherCategory: TemplateCategoryItem | undefined = res.find(
              (item: any) => !item.hasDefault
            );

            if (otherCategory) {
              const templates: TemplateItem[] = otherCategory.templates;

              setData(templates);
            }

            setIsLoading(false);
          }
        })
        .catch(() => setIsLoading(false));
  }, [shouldFetch]);

  const formatted = useMemo(
    () =>
      data.map((item: TemplateItem) => ({
        value: item.id,
        label: item.name,
      })),
    [data]
  );

  return { isLoading, formatted };
};
