import { useCallback, useState } from "react";
import ContractsService from "../../../../../../../../../services/accounts/contracts";
import { FormInstance } from "antd";

export const useCheckAccount = (
  form: FormInstance,
  setFieldError: (value: string[]) => void
) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const search = useCallback(
    (value: number) => {
      setIsDisabled(true);

      ContractsService.searchByNumber(value)
        .then((res) => {
          form.setFieldValue("toContractId", res.id);
          setIsDisabled(false);
        })
        .catch((error) => {
          const { response } = error;
          const { data } = response;

          if (data.status === 400) {
            setFieldError(["Account was not found"]);
          }

          setIsDisabled(false);
        });
    },
    [setFieldError, form]
  );

  return { isDisabled, search };
};
