import { ColumnsType } from "antd/es/table";
import { useCallback, useMemo, MouseEvent } from "react";
import { TemplateCategoryItem, TemplateItem } from "../../../../types/Template";
import { Dropdown, MenuProps, Row, Tag } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useDelete } from "./useDelete";
import { useDownload } from "./useDownload";
import { useSetAsDefault } from "./useSetAsDefault";

export const useTableColumns = (
  category: TemplateCategoryItem | null,
  reloadData: () => void,
  onEdit: (record: TemplateItem) => void
) => {
  const { onDeleteClick } = useDelete(reloadData);

  const { isLoading: isDownloadLoading, onDownloadClick } = useDownload();

  const { isLoading: isUpdatingDefault, onUpdateDefaultClick } =
    useSetAsDefault(reloadData);

  const onEditClick = useCallback(
    (e: MouseEvent<HTMLInputElement>, record: TemplateItem): void => {
      e.stopPropagation();
      onEdit(record);
    },
    [onEdit]
  );

  const getMenuItems = useCallback(
    (record: TemplateItem): MenuProps["items"] => {
      const { id, isDefault } = record;

      const items: MenuProps["items"] = [
        {
          key: "1",
          label: (
            <div
              onClick={(e: MouseEvent<HTMLInputElement>) =>
                onEditClick(e, record)
              }
            >
              Edit
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              id={id}
              onClick={(e: MouseEvent<HTMLInputElement>) =>
                onDownloadClick(e, record.fileName)
              }
            >
              Download
            </div>
          ),
          disabled: isDownloadLoading,
        },
      ];

      category?.hasDefault &&
        items.push({
          key: "3",
          label: (
            <div
              id={id}
              onClick={(e: MouseEvent<HTMLInputElement>) =>
                onUpdateDefaultClick(e, record)
              }
            >
              Set as Default
            </div>
          ),
          disabled: isDefault || isUpdatingDefault,
        });

      items.push({
        key: "4",
        label: (
          <div
            onClick={(e: MouseEvent<HTMLInputElement>) =>
              onDeleteClick(e, record)
            }
          >
            Delete
          </div>
        ),
      });

      return items;
    },
    [
      onEditClick,
      onDownloadClick,
      isDownloadLoading,
      category,
      onUpdateDefaultClick,
      onDeleteClick,
      isUpdatingDefault,
    ]
  );

  const columns: ColumnsType<TemplateItem> = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (value: string, record: TemplateItem) => {
          const showTag: boolean = !!category?.hasDefault && !!record.isDefault;

          const items: MenuProps["items"] = getMenuItems(record);

          return (
            <Row className="align-center justify-space-between my-1">
              <p className="my-0 text-md">{value}</p>
              <Row className="align-center">
                {showTag && <Tag color="blue">Default</Tag>}

                <Dropdown menu={{ items }} trigger={["click"]} className="ml-4">
                  <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
                </Dropdown>
              </Row>
            </Row>
          );
        },
      },
    ],
    [category, getMenuItems]
  );

  return { columns };
};
