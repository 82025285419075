import { useCallback, useEffect, useState } from "react";
import AccountsService from "../../../../../../../services/accounts";
import { IOption } from "../../../../../../../types/Option";
import dayjs from "dayjs";

export const useFetchYears = (
  contractNumber: string,
  setSelectedYear: React.Dispatch<React.SetStateAction<number | null>>
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [options, setOptions] = useState<IOption[]>([]);

  const fetchYears = useCallback(async () => {
    const data: number[] = await AccountsService.fetchYears(contractNumber);

    const currentYear: number = dayjs().year();

    const yearOptions: IOption[] =
      data.length > 0
        ? data.map((item: number) => ({
            label: item.toString(),
            value: item,
          }))
        : [{ label: currentYear.toString(), value: currentYear }];

    setSelectedYear(yearOptions[yearOptions.length - 1].value);

    setOptions(yearOptions);

    setIsLoading(false);
  }, [contractNumber, setSelectedYear]);

  useEffect(() => {
    fetchYears();
  }, [fetchYears]);

  return { options, isLoading };
};
