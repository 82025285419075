import { ReactNode, useCallback, useMemo, useState } from "react";
import { TemplateForm } from "../components/TemplateForm";
import { TemplateCategoryItem, TemplateItem } from "../../../../types/Template";
import { SideBarContainer } from "../../../../components/SideBarContainer";

export const useSidebarControls = (
  category: TemplateCategoryItem | null,
  reloadData: () => void
) => {
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateItem | null>(
    null
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = useCallback((value: any) => {
    value === null && setSelectedTemplate(null);
  }, []);

  const formWrapper: ReactNode = useMemo(() => {
    const formTitle = selectedTemplate ? "Template Details" : "Add Template";

    return (
      <SideBarContainer
        title={formTitle}
        setShowSideBar={setIsOpen}
        setCurrentId={close}
      >
        <TemplateForm
          data={selectedTemplate}
          reloadData={reloadData}
          setIsOpen={setIsOpen}
          setData={setSelectedTemplate}
          parentId={category ? category.id : ""}
          isParentHasDefault={!!category?.hasDefault}
        />
      </SideBarContainer>
    );
  }, [selectedTemplate, close, reloadData, category]);

  const onEdit = useCallback(
    (record: TemplateItem): void => {
      setIsOpen(true);
      setSelectedTemplate(record);
    },
    [setIsOpen]
  );

  return { formWrapper, isOpen, setIsOpen, onEdit };
};
