import { Button, Form, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { useRules } from "./hooks/useRules";
import { useDownload } from "../../../../../../../../../Administration/TemplatesTab/hooks/useDownload";
import ReportService from "../../../../../../../../../../services/reportGeneration";
import { useBeforeUpload } from "../../../../../../../../../../lib/hooks/useBeforeUpload";

type AttachmentsProps = {
  clearError: () => void;
};

export const Attachments = ({ clearError }: AttachmentsProps) => {
  const { isLoading, onDownload } = useDownload(ReportService);

  const { beforeUpload } = useBeforeUpload();

  const onDownlaodClick = useCallback(
    (file: any) => !isLoading && onDownload(file.taskId, file.name),
    [onDownload, isLoading]
  );

  const { rules } = useRules();

  return (
    <Row className="position-relative">
      <Form.Item
        label="Attachments"
        name="attachments"
        className="mb-2 w-100"
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
        rules={rules}
      >
        <Upload
          listType="picture"
          beforeUpload={beforeUpload}
          previewFile={async () => ""}
          showUploadList={{ showDownloadIcon: true }}
          onDownload={onDownlaodClick}
          onRemove={clearError}
        >
          <Button icon={<UploadOutlined />} shape="round">
            Upload DOCX or PDF
          </Button>
        </Upload>
      </Form.Item>
    </Row>
  );
};
