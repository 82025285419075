import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import { useCallback, useMemo, useState } from "react";
import { ReceiverItem } from "./RecieverItem";
import { TemplateSelect } from "./TemplateSelect";
import { FooterButtons } from "./FooterButtons";
import { useFetchPrimaryData } from "../../../pages/Account/components/View/Admin/HeaderActions/components/EmailsModal/hooks/useFetchPrimaryData";
import { Loader } from "../../../pages/Account/components/View/Admin/HeaderActions/components/EmailsModal/components/Loader";
import { useFetchData } from "../../EmailModal/hooks/useFetchData";
import { Letter } from "./Letter";
import { IPrimaryData } from "../../../pages/Account/components/View/Admin/HeaderActions/types/PrimaryData";
import { useSave } from "../hooks/useSave";

type FormContainerProps = {
  form: FormInstance;
  id: string | null | undefined;
  contractId: string;
  primaryData: IPrimaryData | undefined;
  isTemplateGenerating: boolean;
  close: () => void;
  setIsTemplateGenerating: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FormContainer = ({
  form,
  id,
  contractId,
  primaryData,
  isTemplateGenerating,
  close,
  setIsTemplateGenerating,
}: FormContainerProps) => {
  const [sendToData, setSendToData] = useState<any[]>([]);

  const { isLoading, setIsLoading } = useFetchData(id, 0, form);

  const { formattedData: initalData } = useFetchPrimaryData(
    primaryData,
    setIsLoading
  );

  const disabled: boolean = useMemo(() => !!id, [id]);

  const { isSaving, onSave, setIsSaving } = useSave(
    contractId,
    form,
    sendToData,
    close
  );

  const setGlobalIsLoading = useCallback(
    (value: boolean) => {
      setIsTemplateGenerating(value);
      setIsSaving(value);
    },
    [setIsSaving, setIsTemplateGenerating]
  );

  return (
    <Row className="d-block position-relative">
      {isLoading ? (
        <Row className="loader-container position-relative justify-center">
          <Loader />
        </Row>
      ) : (
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          className={`position-relative ${
            isTemplateGenerating ? "blured" : ""
          }`}
          disabled={isSaving}
        >
          {isTemplateGenerating && (
            <Row className="w-100 justify-center">
              <Loader />
            </Row>
          )}

          {!disabled ? (
            <ReceiverItem
              form={form}
              initalData={initalData}
              optionsData={sendToData}
              setOptionsData={setSendToData}
              isLoading={isLoading || isSaving}
              disabled={disabled}
            />
          ) : (
            <Row className="w-100">
              <Col span={20} className="pr-2">
                <Form.Item label="Receiver" name="email">
                  <Select mode="tags" disabled maxTagTextLength={45} />
                </Form.Item>
              </Col>
              <Col span={4} className="pl-2">
                <Form.Item label="Printed Date" name="date">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          )}

          {!id && (
            <TemplateSelect
              form={form}
              contractId={contractId}
              isLoading={isLoading || isSaving}
              disabled={disabled}
              setGlobalIsLoading={setGlobalIsLoading}
            />
          )}

          <Letter form={form} disabled={disabled} />
        </Form>
      )}

      <FooterButtons
        form={form}
        isLoading={isLoading || isSaving}
        disabled={disabled}
        close={close}
        onSave={onSave}
      />
    </Row>
  );
};
