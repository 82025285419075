import { Row, Table } from "antd";
import { Header } from "./components/Header";
import { useFetchCount } from "./hooks/useFetchCount";
import { useFetchData } from "../../lib/hooks/useDefaultFetchData";
import EmailsService from "../../services/emails";
import { useCallback, useMemo, useState } from "react";
import { useTableColumns } from "./hooks/useTableCoumns";
import { TablePagination } from "../../components/TablePagination";
import { Email } from "../../types/Email";
import { useDefualtOnRow } from "../../lib/hooks/useDefaultOnRow";
import "./styles.scss";
import { EmailModal } from "../../components/EmailModal";

export const Emails = () => {
  const {
    total,
    isLoading: isCountLoading,
    request,
    setRequest,
    fetchCount,
    onPageChange,
  } = useFetchCount();

  const [id, setId] = useState<string | null>(null);

  const { data, isLoading, fetchData, setData } = useFetchData<any, Email>(
    EmailsService,
    request
  );

  const reloadData = useCallback((): void => {
    fetchCount();
    fetchData();
  }, [fetchCount, fetchData]);

  const { columns } = useTableColumns(request.type, reloadData, setId);

  const isAllLoading: boolean = useMemo(
    () => isCountLoading && isLoading,
    [isCountLoading, isLoading]
  );

  const { onRow } = useDefualtOnRow(setId);

  const close = useCallback(() => setId(null), []);

  return (
    <Row className="table-page-content emails">
      <Header
        request={request}
        setRequest={setRequest}
        isLoading={isAllLoading}
        reloadData={reloadData}
        setData={setData}
      />
      <Row className="d-block table__container width-100 mx-8 mt-4">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          className="overflow-y-auto clickable-rows-table"
          rowKey={"id"}
          onRow={onRow}
        />
        <TablePagination
          isTotalLoading={isCountLoading}
          total={total}
          currentPage={request.pageNumber}
          pageSize={request.pageSize}
          onPageChange={onPageChange}
        />
      </Row>
      <EmailModal
        id={id}
        close={close}
        sourceType={request.type}
        shouldOpen={!!id}
      />
    </Row>
  );
};
