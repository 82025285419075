import {
  Button,
  Dropdown,
  MenuProps,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from "antd";
import { MoreOutlined, DownOutlined } from "@ant-design/icons";
import { useCallback, useMemo, useState } from "react";
import { useFetchYears } from "../../hooks/useFetchYears";
import { PrintTaxModal } from "./PrintTaxModal";

type HeaderProps = {
  contractId: string;
  isActionsDisabled: boolean;
  isTotalsOpen: boolean;
  selectValue: number | undefined;
  setSelectValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  onRadioChange: (e: RadioChangeEvent) => void;
  onAdd: () => void;
};

export const Header = ({
  contractId,
  isActionsDisabled,
  isTotalsOpen,
  selectValue,
  setSelectValue,
  onRadioChange,
  onAdd,
}: HeaderProps) => {
  const [openTaxModal, setOpenTaxModal] = useState(false);

  const openTax = useCallback(() => setOpenTaxModal(true), []);

  const moreActions: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <Row onClick={onAdd}>Create Payment History</Row>,
      },
    ],
    [onAdd]
  );

  const printOptions: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <Row onClick={openTax}>Tax 1098</Row>,
      },
    ],
    [openTax]
  );

  const { years, isLoading } = useFetchYears(contractId, setSelectValue);

  const radioOptions = useMemo(
    () => [
      { label: "Payments", value: 1 },
      { label: "Totals", value: 2 },
    ],
    []
  );

  const onSelectChange = useCallback(
    (value: number) => setSelectValue(value),
    [setSelectValue]
  );

  return (
    <Row className="d-flex justify-space-between">
      <Row>
        <Radio.Group
          optionType="button"
          options={radioOptions}
          onChange={onRadioChange}
          defaultValue={1}
          className="mr-4 payment-history-switcher"
          disabled={isActionsDisabled}
        />
        {!isTotalsOpen && (
          <Select
            options={years}
            className="payment-history-select"
            value={selectValue}
            disabled={isLoading || isActionsDisabled}
            onChange={onSelectChange}
          />
        )}
      </Row>
      {!isTotalsOpen && (
        <Row>
          <Dropdown
            menu={{ items: printOptions }}
            trigger={["click"]}
            className="mr-4"
          >
            <Button shape="round" className="d-inline-flex align-center ml-4">
              Print <DownOutlined />
            </Button>
          </Dropdown>

          <>
            <Dropdown
              menu={{ items: moreActions }}
              disabled={isActionsDisabled}
              trigger={["click"]}
            >
              <Button icon={<MoreOutlined />} shape="circle" />
            </Dropdown>

            <PrintTaxModal
              contractId={contractId}
              isOpen={openTaxModal}
              setIsOpen={setOpenTaxModal}
              years={years}
            />
          </>
        </Row>
      )}
    </Row>
  );
};
