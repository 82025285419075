import { useEffect, useState } from "react";
import CustomerPaymentsService from "../../../../../../services/customers/payments";

export const useFetchCount = (customerId: string, request: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(1);

  useEffect(() => {
    setIsLoading(true);
    request.year &&
      CustomerPaymentsService.fetchCount({ ...request, customerId })
        .then((res: any) => {
          setIsLoading(false);
          setTotal(res);
        })
        .catch(() => setIsLoading(false));
  }, [customerId, request]);

  return { total, isLoading };
};
