import { Button, Row } from "antd";
import { useCallback, useMemo, useState } from "react";
import { EmailsModal } from "./components/EmailsModal";
import { Print } from "./components/Print";
import { IPrimaryData } from "./types/PrimaryData";

type HeaderActionsProps = {
  isLoading: boolean;
  data: any;
};

export const HeaderActions = ({ isLoading, data }: HeaderActionsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = useCallback(() => setIsOpen(true), []);

  const primaryData: IPrimaryData = useMemo(() => {
    return {
      buyerId: data ? data.primaryBuyerId : "",
      sellerId: data ? data.primarySellerId : "",
    };
  }, [data]);

  return (
    <Row>
      <Button shape="round" disabled={isLoading} onClick={openModal}>
        Send Email
      </Button>
      <Print
        contractId={data?.id || ""}
        isLoading={isLoading}
        primaryData={primaryData}
      />

      <EmailsModal
        contractId={data?.id || ""}
        isOpen={isOpen}
        primaryData={primaryData}
        setIsOpen={setIsOpen}
      />
    </Row>
  );
};
