import { Form, FormInstance, Row, Select } from "antd";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useGetTooltip } from "./hooks/useGetTooltip";
import _ from "lodash";
import { useRules } from "./hooks/useRules";
import { useGetCustomerInfo } from "../../../../pages/Account/components/View/Admin/HeaderActions/components/EmailsModal/components/SendToFormItem/hooks/useGetCustomerInfo";
import { SelectOption } from "../../../../pages/Account/components/View/Admin/HeaderActions/components/EmailsModal/components/SendToFormItem/SelectOption";
import { useTagLabel } from "../../../../pages/Account/components/View/Admin/HeaderActions/components/EmailsModal/components/SendToFormItem/hooks/useTagLabel";
import { useTagRender } from "../../../../pages/Account/components/View/Admin/HeaderActions/components/EmailsModal/components/SendToFormItem/hooks/useTagRender";

type ReceiverItemProps = {
  form: FormInstance;
  initalData: any;
  optionsData: any;
  isLoading: boolean;
  disabled: boolean;
  setOptionsData: React.Dispatch<React.SetStateAction<any>>;
};

export const ReceiverItem = ({
  form,
  initalData,
  optionsData,
  isLoading,
  disabled,
  setOptionsData,
}: ReceiverItemProps) => {
  const { rules } = useRules(optionsData);

  const { isLoading: isFetching, search } = useGetCustomerInfo(setOptionsData);

  useEffect(() => {
    const val = initalData.map((item: any, i: number) => ({ ...item, i }));

    setOptionsData(val);
  }, [initalData, setOptionsData]);

  useEffect(() => {
    try {
      optionsData.length > 2 && form.validateFields();
    } catch {}
  }, [form, optionsData]);

  const options: ReactNode = useMemo(() => {
    const values = optionsData.map((item: any) => {
      const name: string = item.name;

      return (
        <Select.Option key={item.i} className="send-to-select-item">
          <SelectOption name={name} item={item} showEmail={false} />
        </Select.Option>
      );
    });

    return values;
  }, [optionsData]);

  const { getTooltip } = useGetTooltip();

  const { getTagLabel } = useTagLabel();

  const { tagRender } = useTagRender(
    optionsData,
    isLoading,
    getTagLabel,
    getTooltip
  );

  const onSelect = useCallback(
    (value: string, option: any) => {
      if (Object.keys(option).length === 0) {
        const val = +value;

        val && !_.isNaN(val) && search(val);
      }
    },
    [search]
  );

  const dropdownRender = useCallback((originNode: any) => {
    return (
      <Row className="d-block">
        <p className="my-1 mx-3 text-sm title">
          Select from the list or input Customer# and click Enter
        </p>
        {originNode}
      </Row>
    );
  }, []);

  return (
    <Form.Item label="Receiver" name="email" rules={rules} className="mb-4">
      <Select
        mode="tags"
        optionFilterProp="children"
        className="send-to-select"
        tagRender={tagRender}
        onSelect={onSelect}
        loading={isFetching}
        dropdownRender={dropdownRender}
        maxTagTextLength={50}
        disabled={isLoading || disabled}
      >
        {options}
      </Select>
    </Form.Item>
  );
};
