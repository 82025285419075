import { useCallback, useMemo } from "react";
import { PaymentManualUpdated } from "../../../types/PaymentManualUpdated";

export const useUpdateManualUpdated = (
  instance: any,
  manualUpdated?: PaymentManualUpdated,
  setManualUpdated?: React.Dispatch<React.SetStateAction<PaymentManualUpdated>>
) => {
  const lateChargesNames: string[] = useMemo(
    () => ["breakdown_lateChargeAmount", "breakdown_lateChargeToBalance"],
    []
  );

  const feesTaxesNames: string[] = useMemo(
    () => ["payeeFee", "payeeTax", "payerFee", "payerTax"],
    []
  );

  const onFormChange = useCallback(
    (e: any) => {
      if (setManualUpdated && manualUpdated) {
        const updated = { ...manualUpdated };

        const { target } = e;

        if (target) {
          const { id, value } = target;

          const val: number = +value || 0;

          if (id === "interestAmount" && instance.interestAmount !== val) {
            updated.interestAmount = true;
          }

          if (feesTaxesNames.includes(id) && instance[id] !== val) {
            updated.fee = true;
          }

          if (lateChargesNames.includes(id)) {
            const instanceProp = id.split("_")[1];

            if (instance.breakdown[instanceProp] !== val) {
              updated.lateCharge = true;
            }
          }

          if (
            id === "breakdown_principalAmount" &&
            instance.breakdown.principalAmount !== val
          ) {
            updated.principalAmount = true;
          }

          setManualUpdated(updated);
        }
      }
    },
    [
      feesTaxesNames,
      instance,
      manualUpdated,
      setManualUpdated,
      lateChargesNames,
    ]
  );

  return { onFormChange };
};
