import { Card, Col, Form, FormInstance, Row } from "antd";
import { General } from "./General";
import { FeesAndTaxes } from "./FeesAndTaxes";
import { Disbursements } from "./Disbursements";
import { BreakDown } from "./Breakdown";
import { useResetInstance } from "./hooks/useResetInstance";
import { PaymentManualUpdated } from "../../../../types/PaymentManualUpdated";
import { useUpdateManualUpdated } from "../../hooks/useUpdateManualUpdated";

type PaymentFormProps = {
  isLoading: boolean;
  isFormBlured: boolean;
  instance: any;
  form: FormInstance;
  isPaymentPosted: boolean;
  calculate: () => Promise<0 | 1>;
  manualUpdated?: PaymentManualUpdated;
  isHistoryOpened?: boolean;
  isHistoryEdited?: boolean;
  setManualUpdated?: React.Dispatch<React.SetStateAction<PaymentManualUpdated>>;
};

export const PaymentForm = ({
  isLoading,
  isFormBlured,
  instance,
  form,
  isPaymentPosted,
  calculate,
  isHistoryOpened = false,
  isHistoryEdited = true,
  setManualUpdated,
  manualUpdated,
}: PaymentFormProps) => {
  const { resetByDisbursements } = useResetInstance(calculate);

  const { onFormChange } = useUpdateManualUpdated(
    instance,
    manualUpdated,
    setManualUpdated
  );

  return (
    <Row className="d-block form-container pt-4">
      <Form
        layout="vertical"
        form={form}
        disabled={isLoading || isPaymentPosted}
        onChange={onFormChange}
        requiredMark={false}
      >
        <Row className="w-100">
          <Col span={16} className="pr-4">
            <Card className="position-relative">
              <General
                form={form}
                instance={instance}
                calculate={calculate}
                isFormBlured={isFormBlured}
              />
              {isFormBlured && <div className="blur" />}
            </Card>
          </Col>
          <Col span={8}>
            <Card className="position-relative">
              <FeesAndTaxes
                form={form}
                instance={instance}
                calculate={calculate}
                isHistoryOpened={isHistoryOpened}
              />
              {isFormBlured && <div className="blur" />}
            </Card>
          </Col>
        </Row>
        <Row className="w-100 mt-4">
          <Col span={16} className="pr-4">
            <Card className="position-relative second-row-card h-100">
              <BreakDown
                form={form}
                instance={instance}
                calculate={calculate}
                isFormDisabled={isLoading}
                isPaymentPosted={isPaymentPosted}
                isHistoryOpened={isHistoryOpened}
                setManualUpdated={setManualUpdated}
              />
              {isFormBlured && <div className="blur" />}
            </Card>
          </Col>
          <Col span={8}>
            <Card className="position-relative second-row-card">
              <Disbursements
                instance={instance}
                form={form}
                reset={resetByDisbursements}
                isFormDisabled={isLoading}
                isPaymentPosted={isPaymentPosted}
                isHistoryEdited={isHistoryEdited}
                isHistoryOpened={isHistoryOpened}
              />
              {isFormBlured && <div className="blur" />}
            </Card>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};
