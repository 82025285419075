import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";

const fetchAll = async (request: any) => {
  const source: string = request.type === 1 ? "outbox" : "sent";

  const response = await authAxios.get(`${API_URL}DomainEmails/${source}`, {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

const fetchCount = async (request: any) => {
  const source: string = request.type === 1 ? "outbox" : "sent";

  const response = await authAxios.get(
    `${API_URL}DomainEmails/${source}/Count`,
    {
      params: request,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      headers: authHeader(),
    }
  );

  return response.data;
};

const get = async (id: string, type: 0 | 1 | 2) => {
  const source: string = type === 1 ? "outbox" : "sent";

  const result = await authAxios.get(`${API_URL}DomainEmails/${source}/${id}`, {
    headers: authHeader(),
  });

  return result.data;
};

const create = async (request: any) => {
  const result = await authAxios.post(
    `${API_URL}DomainEmails/outbox/create`,
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const sendOne = async (id: string) => {
  const response = await authAxios.put(
    `${API_URL}DomainEmails/outbox/${id}/send`,
    {},
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const sendAll = async () => {
  const response = await authAxios.put(
    `${API_URL}DomainEmails/outbox/sendAll`,
    {},
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const remove = async (id: string) => {
  const result = await authAxios.delete(`${API_URL}DomainEmails/outbox/${id}`, {
    headers: authHeader(),
  });

  return result.data;
};

const removeAll = async () => {
  const result = await authAxios.delete(`${API_URL}DomainEmails/outbox/`, {
    headers: authHeader(),
  });

  return result.data;
};

const download = async (id: string) => {
  const response = await authAxios.get(
    `${API_URL}DomainEmails/outbox/attachments/${id}/download`,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      headers: authHeader(),
      responseType: "blob",
    }
  );

  return response.data;
};

const EmailsService = {
  fetchAll,
  fetchCount,
  get,
  sendOne,
  remove,
  removeAll,
  create,
  sendAll,
  download,
};

export default EmailsService;
