import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import nextLocation from "./nextLocation";
import permissions from "./permissions";
import mailingKey from "./mailingKey";

export default combineReducers({
  auth,
  message,
  nextLocation,
  permissions,
  mailingKey,
});
