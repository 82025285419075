import { useCallback } from "react";
import {
  deletePaymentHistoryRecord,
  editPaymentHistoryRecord,
  recalculateYTDAmounts,
  createPaymentHistoryRecord,
} from "../../../../../../../acions/accounts/paymentHistory";
import { useDefaultDeleteModal } from "../../../../../../../lib/hooks/defaultDeleteModal";
import { FormInstance } from "antd";
import { useAppDispatch } from "../../../../../../../store";
import { useTransformAmounts } from "../../../../../../Payment/hooks/useTransformAmounts";

type TButtonHandlersArgs = {
  reloadData: () => void;
  setIsPaymentEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  form?: FormInstance;
  instance?: any;
  setIsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  customerIds?: any;
  setInitialForm?: () => void;
  setOpenEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useButtonHandlers = ({
  reloadData,
  form,
  setIsPaymentEdited,
  instance,
  setIsDisabled,
  customerIds,
  setInitialForm,
  setOpenEditMode,
}: TButtonHandlersArgs) => {
  const dispatch = useAppDispatch();

  const { transform } = useTransformAmounts();

  const { openDeleteModal } = useDefaultDeleteModal(
    deletePaymentHistoryRecord,
    reloadData
  );

  const onDeleteBtnClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Payment History");
    },
    [openDeleteModal]
  );

  const onCancelClick = useCallback(() => {
    setIsPaymentEdited && setIsPaymentEdited(false);
    setInitialForm && setInitialForm();
    setOpenEditMode && setOpenEditMode(false);
  }, [setIsPaymentEdited, setInitialForm, setOpenEditMode]);

  const onSuccess = useCallback(() => {
    reloadData();
    setIsDisabled && setIsDisabled(false);
    setOpenEditMode && setOpenEditMode(false);
    setIsPaymentEdited && setIsPaymentEdited(false);
  }, [reloadData, setIsDisabled, setIsPaymentEdited, setOpenEditMode]);

  const edit = useCallback(
    async (id: string, req: any) => {
      try {
        await Promise.resolve(dispatch(editPaymentHistoryRecord(id, req)));

        onSuccess();
      } catch (error) {
        setIsDisabled && setIsDisabled(false);
      }
    },
    [dispatch, onSuccess, setIsDisabled]
  );

  const create = useCallback(
    async (req: any) => {
      try {
        await Promise.resolve(dispatch(createPaymentHistoryRecord(req)));

        onSuccess();
      } catch (error) {
        setIsDisabled && setIsDisabled(false);
      }
    },
    [dispatch, onSuccess, setIsDisabled]
  );

  const onSaveClick = useCallback(
    async (id: string | null, contractId: string) => {
      try {
        setIsDisabled && setIsDisabled(true);

        const data = await form?.validateFields();

        const { dueDate, date, interestDateTo } = data;

        const req = transform({
          ...data,
          payerId: customerIds?.payeeId,
          disbursements: instance?.disbursements,
          breakdown: {
            ...instance.breakdown,
            ...data.breakdown,
          },
          contractId,
          dueDate: dueDate.startOf("D").format(),
          date: date.startOf("D").format(),
          interestDateTo: interestDateTo
            ? interestDateTo.startOf("D").format()
            : undefined,
        });

        id ? edit(id, req) : create(req);
      } catch (error) {
        setIsDisabled && setIsDisabled(false);
      }
    },
    [create, customerIds, edit, form, instance, setIsDisabled, transform]
  );

  const onRecalculateClick = useCallback(
    async (id: string) => {
      try {
        setIsDisabled && setIsDisabled(true);

        await Promise.resolve(dispatch(recalculateYTDAmounts(id)));

        reloadData();
        setIsDisabled && setIsDisabled(false);
      } catch (error) {
        setIsDisabled && setIsDisabled(false);
      }
    },
    [dispatch, reloadData, setIsDisabled]
  );

  return { onDeleteBtnClick, onSaveClick, onCancelClick, onRecalculateClick };
};
