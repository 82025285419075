import { useCallback } from "react";

export const useGetTooltip = () => {
  const getTooltip = useCallback(
    (item: any, value: string): string => (item ? `#${item.number}` : value),
    []
  );

  return { getTooltip };
};
