import { useCallback, useState } from "react";
import CustomersService from "../../../../../../../../../../../services/customers";
import { EMAIL_RULE } from "../../../../../../../../../../../lib/validationRules";

export const useGetCustomerInfo = (
  setData: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const search = useCallback(
    (num: number) => {
      setIsLoading(true);

      const request = {
        search: num,
        pageSize: 1,
        pageNumber: 1,
        field: 1,
      };

      CustomersService.fetchAll(request)
        .then((res) => {
          setIsLoading(false);

          if (res.length > 0) {
            setData((prev: any) => {
              const val = [...prev];

              const customer = res[0];

              val.push({
                name: customer.fullName,
                number: num,
                i: num,
                email: customer.email,
                id: customer.id,
              });

              return val;
            });
          }
        })
        .catch(() => setIsLoading(false));
    },
    [setIsLoading, setData]
  );

  const testEmail = useCallback(
    (val: string) => {
      if (EMAIL_RULE.test(val)) {
        setData((prev: any) => {
          const value = [...prev];

          value.push({ email: val, name: val, i: val, isEmail: true });

          return value;
        });
      }
    },
    [setData]
  );

  return { isLoading, search, testEmail };
};
