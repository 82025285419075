import { useMemo } from "react";
import { useFetchData } from "../../../../../../../../../../lib/hooks/useDefaultFetchData";
import PayersService from "../../../../../../../../../../services/accounts/payers";
import { IPayersGet } from "../../../../../../../../../../types/Payer";
import { Form, FormInstance, Row } from "antd";
import { Loader } from "../../../../../HeaderActions/components/EmailsModal/components/Loader";
import { YearsItem } from "./YearsItem";
import dayjs from "dayjs";
import { PayersList } from "./PayersList";
import { IOption } from "../../../../../../../../../../types/Option";

type TRequest = {
  pageNumber: number;
  pageSize: number;
  contractId: string;
};

type FormContentProps = {
  form: FormInstance;
  contractId: string;
  years: IOption[];
};

export const FormContent = ({ form, contractId, years }: FormContentProps) => {
  const request = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: 1000,
      contractId,
    }),
    [contractId]
  );

  const { data, isLoading } = useFetchData<TRequest, IPayersGet>(
    PayersService,
    request
  );

  const initialValues = useMemo(
    () => ({
      year: dayjs().year(),
    }),
    []
  );

  return (
    <Row className="d-block position-relative">
      {isLoading ? (
        <Row className="loader-container position-relative justify-center">
          <Loader />
        </Row>
      ) : (
        <Row>
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            initialValues={initialValues}
          >
            <YearsItem years={years} />

            <PayersList data={data} />
          </Form>
        </Row>
      )}
    </Row>
  );
};
