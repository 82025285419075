import { Rule } from "antd/es/form";
import { useMemo } from "react";
import { UploadFile as UploadFileType } from "antd/es/upload";
import { FILE_SIZE_EXCEED, MAX_FILE_SIZE } from "../../../../../lib/constants";

export const useRules = () => {
  const rules: Rule[] = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: UploadFileType[]) {
          if (value && value.length > 0) {
            const size: number | undefined = value[0].size;

            if (!size || +size > MAX_FILE_SIZE) {
              return Promise.reject(new Error(FILE_SIZE_EXCEED));
            }
          }

          return Promise.resolve();
        },
      }),
    ];
  }, []);

  return { rules };
};
