import { useCallback } from "react";
import { DOCX_TYPE, PDF_TYPE } from "../constants";
import { Upload } from "antd";

export const useBeforeUpload = () => {
  const beforeUpload = useCallback((file: File) => {
    const isAllowed = file.type === DOCX_TYPE || file.type === PDF_TYPE;

    return isAllowed ? false : Upload.LIST_IGNORE;
  }, []);

  return { beforeUpload };
};
