import { Form, DatePicker, Radio, Checkbox, Button, FormInstance } from "antd";
import dayjs from "../../../configs/dayjs";
import { BATCH_TYPES, DATE_FORMAT } from "../../../lib/constants";
import { useMemo, useState } from "react";
import { useButtonHandlers } from "./hooks/useButtonHandlers";

const { RangePicker } = DatePicker;

type TPrintFormProps = {
  isBatchReport: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContentType: React.Dispatch<React.SetStateAction<number>>;
  contentType: number;
};

export const PrintForm = ({
  isBatchReport,
  setIsOpen,
  setContentType,
  contentType,
}: TPrintFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const sortOptions = useMemo(
    () => [
      { value: 1, label: "Date" },
      { value: 3, label: "Payment#" },
      { value: 5, label: "Account#" },
      { value: 7, label: "Amount" },
    ],
    []
  );

  const initialValues = useMemo(() => {
    const now = dayjs();

    const value: any = {
      dates: [now, now],
      paymentOrder: 1,
      batchType: 0,
    };

    return value;
  }, []);

  const batchOptions = useMemo(() => {
    const value = [...BATCH_TYPES];

    value.splice(1, 0, { label: "Warehouse", value: 3 });

    return value;
  }, []);

  const { onCancelClick, onPrint } = useButtonHandlers({
    setIsOpen,
    setContentType,
    form,
    contentType,
    setIsDownloading,
  });

  return (
    <>
      <Form
        layout="vertical"
        requiredMark={false}
        className="pt-6 processing-print-form"
        initialValues={initialValues}
        form={form}
        disabled={isDownloading}
      >
        <Form.Item label="Batch Dates" name="dates" className="mb-8">
          <RangePicker format={DATE_FORMAT} />
        </Form.Item>
        <div className="d-flex">
          {isBatchReport ? (
            <Form.Item label="Batch Types" name="batchTypes" className="pr-8">
              <Checkbox.Group
                options={batchOptions}
                className="d-flex flex-column"
              />
            </Form.Item>
          ) : (
            <Form.Item label="Batch Type" name="batchType" className="pr-8">
              <Radio.Group
                options={[{ label: "All Types", value: 0 }].concat(
                  batchOptions
                )}
                className="d-flex flex-column"
              />
            </Form.Item>
          )}
          {isBatchReport && (
            <Form.Item
              label="Sort Payments by"
              name="paymentOrder"
              className="pl-8"
            >
              <Radio.Group
                options={sortOptions}
                className="d-flex flex-column"
              />
            </Form.Item>
          )}
        </div>
      </Form>
      <div className="w-100 d-flex justify-end pt-6 pb-1">
        <Button
          type="primary"
          shape="round"
          disabled={isDownloading}
          loading={isDownloading}
          onClick={onPrint}
        >
          Print
        </Button>
        <Button
          shape="round"
          className="ml-2"
          disabled={isDownloading}
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
