import { Button, Row } from "antd";

type FooterProps = {
  sourceType: 0 | 1 | 2;
  close: () => void;
};

export const Footer = ({ close, sourceType }: FooterProps) => {
  return (
    <Row className="w-100 justify-end pa-4">
      {sourceType === 1 && (
        <Button type="primary" shape="round" className="ml-2">
          Send
        </Button>
      )}
      <Button
        type={sourceType === 1 ? "default" : "primary"}
        shape="round"
        className="ml-2"
        onClick={close}
      >
        {sourceType === 1 ? "Cancel" : "Close"}
      </Button>
    </Row>
  );
};
