import { useCallback, useMemo, useState } from "react";
import { CustomModal } from "../../lib/CustomModal";
import { FormContainer } from "./components/FormContainer";
import { Form, FormInstance } from "antd";
import "./styles.scss";
import { IPrimaryData } from "../../pages/Account/components/View/Admin/HeaderActions/types/PrimaryData";

type LetterModalProps = {
  id: string | null | undefined;
  contractId: string;
  primaryData: IPrimaryData | undefined;
  shouldOpen: boolean;
  close: () => void;
};

export const LetterModal = ({
  id,
  contractId,
  primaryData,
  shouldOpen,
  close,
}: LetterModalProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [isTemplateGenerating, setIsTemplateGenerating] =
    useState<boolean>(false);

  const closeModal = useCallback(() => {
    close();
    form.resetFields();
  }, [close, form]);

  const title: string = useMemo(
    () => (id ? "Letter Details" : "Print Letter"),
    [id]
  );

  return (
    <CustomModal
      isModalOpen={shouldOpen}
      setIsModalOpen={closeModal}
      width={600}
      titleText={title}
      headerClass="processing-print-modal--header"
      modalClass="letter-modal"
      destroyOnClose
    >
      <FormContainer
        id={id}
        contractId={contractId}
        primaryData={primaryData}
        form={form}
        isTemplateGenerating={isTemplateGenerating}
        close={closeModal}
        setIsTemplateGenerating={setIsTemplateGenerating}
      />
    </CustomModal>
  );
};
