import { FormInstance } from "antd";
import { useCallback } from "react";

export const useButtonHandlers = (
  form: FormInstance,
  initialValue: string,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  calculate: () => void
) => {
  const close = useCallback(() => {
    form.setFieldValue(["breakdown", "interestAfter"], initialValue);
    setIsOpen(false);
  }, [form, initialValue, setIsOpen]);

  const onSaveClick = useCallback(async () => {
    try {
      await calculate();

      setIsOpen(false);
    } catch (error) {}
  }, [calculate, setIsOpen]);

  return { close, onSaveClick };
};
