import { Form, FormInstance, Modal } from "antd";
import { MODAL_MASK_COLOR } from "../../../../../../../../../lib/constants";
import { useCallback } from "react";
import "./styles.scss";
import { FormButtons } from "./components/FormButtons";
import { FormContent } from "./components/FormIContent";
import { IOption } from "../../../../../../../../../types/Option";
import { usePrint } from "./hooks/usePrint";

type PrintTaxModalPros = {
  contractId: string;
  isOpen: boolean;
  years: IOption[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PrintTaxModal = ({
  contractId,
  isOpen,
  years,
  setIsOpen,
}: PrintTaxModalPros) => {
  const [form]: FormInstance[] = Form.useForm();

  const close = useCallback(() => {
    setIsOpen(false);
    form.resetFields();
  }, [setIsOpen, form]);

  const { onSave } = usePrint(form, close);

  return (
    <Modal
      open={isOpen}
      title="Print Tax 1098 Report"
      width={315}
      onCancel={close}
      centered
      maskStyle={{ backgroundColor: MODAL_MASK_COLOR }}
      wrapClassName="z-1000 position-fixed"
      className="bordered-modal print-tax-modal"
      footer={<FormButtons close={close} onSave={onSave} />}
    >
      <FormContent form={form} contractId={contractId} years={years} />
    </Modal>
  );
};
