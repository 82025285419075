import { FormInstance } from "antd";
import { IPayeeDisbursementsBase } from "../../../../../../types/PayeeDisbursements";
import { useAppDispatch } from "../../../../../../store";
import { setErrorMessage } from "../../../../../../acions/actionsCreators/messageActions";
import { PRIMARY_PAYEE_EXISTS } from "../../../../../../lib/constants";
import { useCallback } from "react";
import { useTransformRequest } from "../../../../../Account/components/View/Admin/Details/components/PayeeDisbursements/hooks/useTransformRequest";

export const useSaveRecord = (
  disbursements: IPayeeDisbursementsBase[],
  reset: (value: IPayeeDisbursementsBase[]) => any
) => {
  const { transform } = useTransformRequest();

  const dispatch = useAppDispatch();

  const showPrimaryError = useCallback(() => {
    dispatch(setErrorMessage(PRIMARY_PAYEE_EXISTS, true));
    return true;
  }, [dispatch]);

  const saveRecord = useCallback(
    async (
      form: FormInstance,
      customerIds: any,
      close: () => void,
      isNew: boolean,
      index: string | null,
      customerName?: string
    ) => {
      try {
        let res = await form.validateFields();

        let updatedDisbursementArr = [...disbursements];

        res = {
          ...res,
          ...customerIds,
          payeeName: customerName,
          payeeNumber: res.payeeId,
          type: res.type === "Impound Distribution" ? 101 : res.type,
          method: res.method === "Copy" ? 9 : res.method,
        };

        transform(res);

        const primaryIndex = updatedDisbursementArr.findIndex(
          (item: IPayeeDisbursementsBase) => item.type === 0
        );

        let hasPrimaryError: boolean = false;

        if (!index) {
          if (primaryIndex > -1 && res.type === 0) {
            hasPrimaryError = showPrimaryError();
          } else {
            updatedDisbursementArr.push(res);
          }
        } else {
          const itemIndex = updatedDisbursementArr.findIndex(
            (item: IPayeeDisbursementsBase) => item.index === index
          );

          if (itemIndex > -1) {
            if (
              primaryIndex > -1 &&
              res.type === 0 &&
              primaryIndex !== itemIndex
            ) {
              hasPrimaryError = showPrimaryError();
            } else {
              updatedDisbursementArr[itemIndex] = {
                ...updatedDisbursementArr[itemIndex],
                ...res,
              };
            }
          }
        }

        if (!hasPrimaryError) {
          await reset(updatedDisbursementArr);

          !isNew && close();
        }
      } catch {}
    },
    [disbursements, reset, showPrimaryError, transform]
  );

  return { saveRecord };
};
