import { Table } from "antd";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";

type SummaryRowProps = {
  data: any;
};

export const SummaryRow = ({ data }: SummaryRowProps) => {
  return (
    <Table.Summary.Row className="table-footer total-row">
      <Table.Summary.Cell index={0}>
        <p className="my-0 text-md w-500">Total</p>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} />
      <Table.Summary.Cell index={2} align="right">
        <p className="my-0 text-md w-500">
          {getFormattedCurrency(data.total.amount)}
        </p>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3} />
      <Table.Summary.Cell index={4} align="right">
        <p className="my-0 text-md w-500">
          {getFormattedCurrency(data.total.principalPaid)}
        </p>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={5} align="right">
        <p className="my-0 text-md w-500">
          {getFormattedCurrency(data.total.interestPaid)}
        </p>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={6} align="right">
        <p className="my-0 text-md w-500">
          {getFormattedCurrency(data.total.fees)}
        </p>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={7} align="right">
        <p className="my-0 text-md w-500">
          {getFormattedCurrency(data.total.paymentImpound)}
        </p>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={8} />
    </Table.Summary.Row>
  );
};
