import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ReactNode, useMemo } from "react";
import {
  MAX_ADDRESS_LINE,
  MAX_CITY,
  WRONG_ZIP_FORMAT,
} from "../../../../../../lib/constants";
import { ZIP_INPUT_RULE } from "../../../../../../lib/validationRules";
import { AddressLabel } from "./AddressLabel";
import { PrintLabel } from "./PrintLabel";

type AddressFormProps = {
  propName: string;
  statesList: any[];
  selectedItemId: string | null | undefined;
  form: FormInstance;
};

export const AddressForm = ({
  propName,
  statesList,
  selectedItemId,
  form,
}: AddressFormProps) => {
  const addressLabel: ReactNode = useMemo(() => {
    return selectedItemId ? (
      <Row className="align-center">
        <AddressLabel propName={propName} form={form} />
        <PrintLabel customerId={selectedItemId || ""} propName={propName} />
      </Row>
    ) : (
      <label style={{ marginBottom: "2px" }}>Address</label>
    );
  }, [selectedItemId, propName, form]);

  return (
    <>
      <Row className="mb-4 w-100">
        {addressLabel}
        <Form.Item
          name={[propName, "addressLine"]}
          className="w-100 mb-0"
          rules={[
            {
              max: 100,
              message: MAX_ADDRESS_LINE,
              validateTrigger: "onSubmit",
            },
          ]}
        >
          <TextArea rows={1} />
        </Form.Item>
      </Row>
      <Row className="mb-4 w-100">
        <Form.Item
          label="City"
          name={[propName, "city"]}
          className="w-100 mb-0"
          rules={[
            {
              max: 50,
              message: MAX_CITY,
              validateTrigger: "onSubmit",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Row>
      <Row className="mb-4 w-100">
        <Col span={12} className="pr-1">
          <Form.Item
            label="State"
            name={[propName, "state"]}
            className="w-100 mb-0"
          >
            <Select options={statesList} allowClear showSearch />
          </Form.Item>
        </Col>
        <Col span={12} className="pl-2">
          <Form.Item
            label="ZIP"
            name={[propName, "zipCode"]}
            className="w-100 mb-0"
            rules={[
              {
                pattern: ZIP_INPUT_RULE,
                message: WRONG_ZIP_FORMAT,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
