import { useMemo } from "react";
import { IPayeeDisbursementsBase } from "../../../../../../types/PayeeDisbursements";

export const useGetFormattedValues = (instance: any) => {
  const payeesNumber: any = useMemo(() => {
    return instance && instance.contract
      ? {
          primaryBuyerNumber: instance.contract.primaryPayerNumber,
          primarySellerNumber: instance.contract.primaryPayeeNumber,
        }
      : {};
  }, [instance]);

  const contractId: string = useMemo(
    () => (instance ? instance.contractId : ""),
    [instance]
  );

  const disbursements: IPayeeDisbursementsBase[] = useMemo(() => {
    if (instance && instance.disbursements) {
      return instance.disbursements.map(
        (item: IPayeeDisbursementsBase, index: number) => ({
          ...item,
          index: `${index}`,
        })
      );
    }

    return [];
  }, [instance]);

  return { payeesNumber, contractId, disbursements };
};
