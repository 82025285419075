import { Modal as AntdModal } from "antd";
import { ReactNode } from "react";

type ModalProps = {
  title: ReactNode;
  footer: ReactNode;
  isOpen: boolean;
  children: ReactNode;
  width?: number;
  className?: string;
};

export const Modal = ({
  title,
  footer,
  isOpen,
  children,
  width = 600,
  className = "terms-modal",
}: ModalProps) => {
  return (
    <AntdModal
      title={title}
      footer={footer}
      open={isOpen}
      className={className}
      width={width}
      centered
      wrapClassName="z-1000 position-fixed before-payment-modal"
      closable={true}
    >
      {children}
    </AntdModal>
  );
};
