import { Rule } from "antd/es/form";
import { useMemo } from "react";
import {
  CUSTOMER_NOT_FOUND,
  RECEIVER_REQUIRED,
} from "../../../../../lib/constants";

export const useRules = (optionsData: any) => {
  const rules = useMemo(
    () => [
      () => ({
        validateTrigger: "onSubmit",
        validator(_r: Rule, value: undefined | string[]) {
          if (!value || value.length === 0) {
            return Promise.reject(new Error(RECEIVER_REQUIRED));
          }

          return Promise.resolve();
        },
      }),
      () => ({
        validator(_r: Rule, value: undefined | string[]) {
          const messages = new Set();

          value &&
            value.forEach((item: string) => {
              const val = optionsData.find(
                (data: any) => data.i.toString() === item
              );

              if (!val) {
                messages.add(CUSTOMER_NOT_FOUND);
              }
            });

          const msgArr = Array.from(messages);

          return msgArr.length > 0 ? Promise.reject(msgArr) : Promise.resolve();
        },
      }),
    ],
    [optionsData]
  );

  return { rules };
};
