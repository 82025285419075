import { useCallback, useEffect, useState } from "react";
import PaymentHistoryService from "../../../../../../../services/accounts/paymentHistory";
import { usePrevious } from "../../../../../../../lib/hooks/previous";
import dayjs from "dayjs";
import { IOption } from "../../../../../../../types/Option";

export const useFetchYears = (
  accountId: string,
  setSelectValue: React.Dispatch<React.SetStateAction<number | undefined>>
) => {
  const [years, setYears] = useState<IOption[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const prevAccountId = usePrevious(accountId);

  const fetch = useCallback(() => {
    setIsLoading(true);

    PaymentHistoryService.fetchYears({ contractId: accountId })
      .then((res: number[]) => {
        const options: IOption[] = res.map((year) => ({
          label: `Year: ${year}`,
          value: year,
        }));

        const defaultYear: number = dayjs().year();
        const defaultOption: IOption = {
          label: `Year: ${defaultYear}`,
          value: defaultYear,
        };

        setSelectValue(
          options.length > 0 ? options[options.length - 1].value : defaultYear
        );

        setYears(options.length > 0 ? options : [defaultOption]);

        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [accountId, setSelectValue]);

  useEffect(() => {
    if (!prevAccountId) {
      fetch();
    }
  }, [fetch, prevAccountId]);

  return { years, isLoading };
};
