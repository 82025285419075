import { Button, Form, Row, Upload } from "antd";
import { useCallback, useMemo } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDownload } from "../../../pages/Administration/TemplatesTab/hooks/useDownload";
import EmailsService from "../../../services/emails";
import MailingsService from "../../../services/accounts/mailings";
import { useBeforeUpload } from "../../../lib/hooks/useBeforeUpload";

type AttachmentsProps = {
  sourceType: 0 | 1 | 2;
};

export const Attachments = ({ sourceType }: AttachmentsProps) => {
  const { onDownload } = useDownload(
    sourceType !== 0 ? EmailsService : MailingsService
  );

  const { beforeUpload } = useBeforeUpload();

  const onDownlaodClick = useCallback(
    (file: any) => onDownload(file.id, file.name),
    [onDownload]
  );

  const showUploadListProps = useMemo(
    () => ({ showDownloadIcon: true, showRemoveIcon: sourceType === 1 }),
    [sourceType]
  );

  return (
    <Row className="position-relative">
      <Form.Item
        label="Attachments"
        name="fileList"
        className="mb-2 w-100"
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
      >
        <Upload
          listType="picture"
          beforeUpload={beforeUpload}
          previewFile={async () => ""}
          showUploadList={showUploadListProps}
          onDownload={onDownlaodClick}
        >
          {sourceType === 1 && (
            <Button icon={<UploadOutlined />} shape="round">
              Upload DOCX or PDF
            </Button>
          )}
        </Upload>
      </Form.Item>
    </Row>
  );
};
