import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import BatchPaymentsService from "../../../../../services/processing/payments";
import { Dayjs } from "dayjs";
import { PaymentManualUpdated } from "../../../../../types/PaymentManualUpdated";
import { useTransformAmounts } from "../../../hooks/useTransformAmounts";

export const useCalculate = (
  form: FormInstance,
  instance: any,
  isFormBlured: boolean,
  setForm: (data: any, isFormBlured: boolean) => void,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  manualUpdated: PaymentManualUpdated,
  setManualUpdated: React.Dispatch<React.SetStateAction<PaymentManualUpdated>>
) => {
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const formatDate = (value: Dayjs): string | null => {
    return value ? value.startOf("D").format() : null;
  };

  const { transform } = useTransformAmounts();

  const calculate = useCallback(
    async (toUpdate?: {
      name: string;
      value: any;
      isUpdatedField?: boolean;
    }) => {
      const manualUpdatedVal = { ...manualUpdated };

      if (toUpdate && toUpdate.name === "disbursements") {
        manualUpdatedVal.disbursements = true;
      }

      try {
        let res = await form.validateFields();

        const { date, interestDateTo } = res;

        const { numberPayments, type } = res;

        if (type !== 2 && !numberPayments) {
          res.numberPayments = 1;
        }

        res = {
          ...res,
          date: formatDate(date),
          interestDateTo: formatDate(interestDateTo),
          batchId: instance.batchId,
          contractId: instance.contractId,
          breakdown: {
            ...res.breakdown,
          },
          disbursements:
            toUpdate && toUpdate.name === "disbursements"
              ? toUpdate.value
              : instance.disbursements,
          manualUpdated: manualUpdatedVal,
        };

        if (toUpdate && toUpdate.isUpdatedField) {
          res.updatedField = toUpdate.name;
        }

        setIsLoading(true);

        const calculated = await BatchPaymentsService.calculate(transform(res));

        setHasChanges(true);

        setManualUpdated(manualUpdatedVal);

        setIsLoading(false);
        setForm(calculated, isFormBlured);

        return 1;
      } catch {
        setIsLoading(false);

        return 0;
      }
    },
    [
      form,
      instance,
      setIsLoading,
      setForm,
      isFormBlured,
      manualUpdated,
      setManualUpdated,
      transform,
    ]
  );

  return { calculate, hasChanges, setHasChanges };
};
