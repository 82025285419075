import { Form, FormInstance, Radio, RadioChangeEvent, Select } from "antd";
import {
  PAYEE_TYPES,
  PERCENT_LIMIT,
} from "../../../../../../../../../lib/constants";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FloatNumberInput } from "../../../../../../../../../components/FloatNumberInput";
import { PayeeInput } from "./PayeeInput";
import { Rule } from "antd/es/form";

type TPayeeFormProps = {
  form: FormInstance;
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  onCustomerFound: (number: number | null, name: string[]) => void;
  hasPayeeId: any;
  hasAlternativePayeeId: any;
  isFormDisabled: boolean;
  isHistoryOpened?: boolean;
  isPayment?: boolean;
};

export const MainPart = ({
  form,
  payeesNumber,
  onCustomerFound,
  hasPayeeId,
  hasAlternativePayeeId,
  isFormDisabled,
  isHistoryOpened = false,
  isPayment,
}: TPayeeFormProps) => {
  const [isPercentage, setIsPercentage] = useState<boolean>(
    form.getFieldValue("amountSwitcher") === 2
  );

  const [isRadioVisible, setIsRadioVisible] = useState<boolean>(
    ![5, 6, 7].includes(form.getFieldValue("type"))
  );

  const onRadioChange = useCallback((e: RadioChangeEvent) => {
    setIsPercentage(e.target.value === 2);
  }, []);

  const onSelectChange = useCallback(
    (e: number) => {
      const isDollarOnly: boolean = [5, 6, 7].includes(e);

      setIsRadioVisible(!isDollarOnly);

      if (isDollarOnly) {
        setIsPercentage(false);
      } else {
        setIsPercentage(form.getFieldValue("amountSwitcher") === 2);
      }
    },
    [form]
  );

  const percentageRules: Rule[] = useMemo(
    () => [
      { required: true },
      {
        type: "number",
        transform: (value: any) => +value,
        max: 100,
        message: PERCENT_LIMIT,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  const amountRef = useRef<any>(null);

  useEffect(() => {
    if (isPayment) {
      const { current } = amountRef;

      if (current) {
        current.inputElement.focus();
        current.inputElement.select();
      }
    }
  }, [amountRef, isPayment]);

  const payeeTypes = useMemo(() => {
    let res = PAYEE_TYPES.filter((item: any) => item.value !== 101);

    if (!isHistoryOpened) {
      res = res.filter((item: any) => item.value !== 102);
    }
    return res;
  }, [isHistoryOpened]);

  return (
    <div className="pb-4">
      <Form.Item name="type" label="Payee Type" className="mb-4">
        <Select options={payeeTypes} onChange={onSelectChange} />
      </Form.Item>
      <PayeeInput
        form={form}
        label="Payee"
        name={["payeeId"]}
        onCustomerFound={onCustomerFound}
        hasId={hasPayeeId}
        payeesNumber={payeesNumber}
        isFormDisabled={isFormDisabled}
      />
      {!isHistoryOpened && (
        <PayeeInput
          form={form}
          label="Alternate Payee"
          name={["alternativePayeeId"]}
          onCustomerFound={onCustomerFound}
          hasId={hasAlternativePayeeId}
          payeesNumber={payeesNumber}
          isFormDisabled={isFormDisabled}
          isFieldRequired={false}
        />
      )}
      <div className="d-flex">
        {isPercentage ? (
          <FloatNumberInput
            name="percentage"
            label="Amount"
            prefix="%"
            decimalLimit={4}
            form={form}
            disabled={isFormDisabled}
            className="flex-grow-1"
            rules={percentageRules}
          />
        ) : (
          <FloatNumberInput
            name="amount"
            label="Amount"
            form={form}
            disabled={isFormDisabled}
            className="flex-grow-1"
            rules={[{ required: true }]}
            customRef={amountRef}
            includeThousandsSeparator
          />
        )}
        {!isPayment && isRadioVisible && (
          <Form.Item name="amountSwitcher">
            <Radio.Group
              options={[
                { label: "$", value: 1 },
                { label: "%", value: 2 },
              ]}
              optionType="button"
              className="ml-2 mt-6"
              onChange={onRadioChange}
              disabled={isFormDisabled}
            />
          </Form.Item>
        )}
      </div>
    </div>
  );
};
