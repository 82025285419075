import { Modal } from "antd";
import { useCallback } from "react";
import { Footer } from "./components/Footer";
import { FormItems } from "./components/FormItems";
import "./styles.scss";
import { MODAL_MASK_COLOR } from "../../lib/constants";

type EmailModalProps = {
  id: string | null;
  sourceType: 0 | 1 | 2;
  shouldOpen: boolean;
  close: () => void;
};

export const EmailModal = ({
  id,
  close,
  sourceType,
  shouldOpen,
}: EmailModalProps) => {
  const closeModal = useCallback(() => {
    close();
  }, [close]);

  return (
    <Modal
      title="Emal Details"
      open={shouldOpen}
      width={600}
      wrapClassName="z-1000 position-fixed"
      maskStyle={{ backgroundColor: MODAL_MASK_COLOR }}
      className="bordered-modal emails-modal"
      centered
      onCancel={closeModal}
      footer={<Footer close={close} sourceType={sourceType} />}
    >
      <FormItems id={id || ""} sourceType={sourceType} />
    </Modal>
  );
};
