import { useCallback } from "react";

export const useOnFail = (
  setError: React.Dispatch<React.SetStateAction<any | null>>
) => {
  const onFail = useCallback((err: any) => setError(err), [setError]);

  const clearError = useCallback(() => setError(null), [setError]);

  return { onFail, clearError };
};
