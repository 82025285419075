import { useCallback } from "react";
import {
  transformToFloat,
  transformToFormatted,
} from "../../../../../../../../../lib/transformToFloat";

export const useTransformRequest = (isRequest: boolean = true) => {
  const transform = useCallback(
    (data: any) => {
      ["amount", "overrideFeeAmount", "overrideFeeTax"].forEach(
        (prop: string) => {
          data[prop] = isRequest
            ? transformToFloat(data[prop])
            : transformToFormatted(data[prop]) || null;
        }
      );
    },
    [isRequest]
  );

  return { transform };
};
