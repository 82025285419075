import { ColumnsType } from "antd/es/table";
import { ReactNode, useCallback, useMemo, MouseEvent } from "react";
import { DATE_FORMAT } from "../../../lib/constants";
import dayjs from "../../../configs/dayjs";
import { Button, Dropdown, MenuProps, Row, Tooltip } from "antd";
import { SendOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useStatusLabel } from "./useStatusLabel";
import { useSendOne } from "./useSendOne";
import { useDelete } from "./useDelete";
import { Email } from "../../../types/Email";
import { EMAIL_RULE } from "../../../lib/validationRules";

export const useTableColumns = (
  type: 1 | 2,
  reloadData: () => void,
  setId: React.Dispatch<React.SetStateAction<string | null>>
) => {
  const { getStatusLabel } = useStatusLabel();

  const { isSending, send } = useSendOne(reloadData);

  const { remove } = useDelete(reloadData);

  const onSend = useCallback(
    (e: MouseEvent<any>) => {
      const id = e.currentTarget.id;
      send(id);
    },
    [send]
  );

  const onDelete = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      remove(e.currentTarget.id);
    },
    [remove]
  );

  const onView = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setId(e.currentTarget.id);
    },
    [setId]
  );

  const getStatusValue = useCallback(
    (email: string, error: boolean, status: 1 | 2 | 3) => {
      if (!EMAIL_RULE.test(email)) {
        return "warning";
      }

      if (status === 3) {
        return "processing";
      }

      if (error) {
        return "error";
      }

      return "";
    },
    []
  );

  const columns: ColumnsType<Email> = useMemo(() => {
    const cols = [
      {
        title: "Created Date",
        dataIndex: "date",
        key: "date",
        width: "14%",
        render: (value: string) => dayjs(value).format(DATE_FORMAT),
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        width: "32%",
        ellipsis: true,
        render: (value: string) => value,
      },
      {
        title: "Account",
        dataIndex: "contractNumber",
        key: "contractNumber",
        width: "13%",
        ellipsis: true,
        render: (value: string) => value,
      },
      {
        title: "Receiver",
        dataIndex: "receiverName",
        key: "receiverName",
        width: "31%",
        ellipsis: true,
        render: (value: string, record: any) => {
          const { email, error, status } = record;

          const statusValue = getStatusValue(email, error, status);

          return (
            <Row className="justify-space-between align-center w-100">
              <Row className="d-block receiver-name">
                <p className="my-0 text-md elipsis">{value}</p>
                <p className="my-0 text-sm title elipsis">{email}</p>
              </Row>
              {type === 1 && statusValue && getStatusLabel(statusValue)}
            </Row>
          );
        },
      },
    ];

    type === 1 &&
      cols.push({
        title: "",
        dataIndex: "actions",
        key: "actions",
        width: "10%",
        ellipsis: true,
        render: (_: any, record: any) => {
          const { id, email, error, status } = record;

          const statusValue: string = getStatusValue(email, error, status);

          const disabled =
            statusValue === "processing" || statusValue === "warning";

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <Row id={id} onClick={onView}>
                  View
                </Row>
              ),
            },
            {
              key: "2",
              label: (
                <Row id={id} onClick={onDelete}>
                  Delete
                </Row>
              ),
            },
          ];

          const sendBtn: ReactNode = disabled ? (
            <Tooltip placement="top" title="Fix invalid email first">
              <Button
                className="mr-4"
                type="text"
                icon={<SendOutlined />}
                disabled={disabled || isSending}
              />
            </Tooltip>
          ) : (
            <Button
              className="mr-4"
              type="text"
              icon={<SendOutlined />}
              disabled={disabled || isSending}
              id={id}
              onClick={onSend}
            />
          );

          return (
            <Row>
              {sendBtn}
              <Dropdown menu={{ items }} trigger={["click"]}>
                <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
              </Dropdown>
            </Row>
          );
        },
      });

    return cols;
  }, [
    type,
    getStatusValue,
    getStatusLabel,
    onDelete,
    isSending,
    onSend,
    onView,
  ]);

  return { columns };
};
