import { UPDATE_KEY } from "../acions/types";

const initialState = { key: false };

export default function mailingKey(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case UPDATE_KEY:
      return { key: !state.key };
    default:
      return state;
  }
}
