import { ColumnsType } from "antd/es/table";
import { useMemo } from "react";
import { IBatchDisbursements } from "../../../../../../../types/PayeeDisbursements";
import dayjs from "../../../../../../../configs/dayjs";
import {
  DATE_FORMAT,
  DISBURSEMENTS_METHOD_TYPES,
  PAYEE_TYPES,
} from "../../../../../../../lib/constants";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";
import { useGetMethodColumnDescription } from "../../../../../../Account/components/View/Admin/Details/components/PayeeDisbursements/hooks/useGetMethodColumnDescription";

export const useGetTableColumns = () => {
  const { getLine } = useGetMethodColumnDescription();

  const columns: ColumnsType<IBatchDisbursements> = useMemo(
    () => [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        width: "8%",
        sorter: true,
        render: (value: string) =>
          value ? dayjs(value).format(DATE_FORMAT) : "",
      },
      {
        title: "Account#",
        key: "contractNumber",
        dataIndex: "contractNumber",
        width: "9%",
        sorter: true,
      },
      {
        title: "Payee",
        key: "payeeName",
        dataIndex: "payeeName",
        width: "16%",
        sorter: true,
        ellipsis: true,
        render: (value: string) => value,
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        width: "12%",
        sorter: true,
        render: (value: number) =>
          PAYEE_TYPES.find((type) => type.value === value)?.label,
      },
      {
        title: "Amount",
        key: "amount",
        dataIndex: "amount",
        width: "9%",
        sorter: true,
        align: "right",
        render: (value: number) => getFormattedCurrency(value),
      },
      {
        title: "Method",
        key: "method",
        dataIndex: "method",
        width: "16%",
        sorter: true,
        render: (value: number, record: IBatchDisbursements) => {
          const {
            bankAccountNumber,
            checkNumber,
            aggregated,
            toContactNumber,
          } = record;

          const method = DISBURSEMENTS_METHOD_TYPES.find(
            (item) => item.value === value
          )?.label;

          const description = getLine({
            methodType: value,
            bankAccountNumber,
            accountNumber: toContactNumber || 0,
            checkNumber,
          });

          return (
            <div className="d-flex flex-column">
              <div className="w-100">
                {aggregated ? "+" : ""}
                {method}
              </div>
              <span className="method-description">{description}</span>
            </div>
          );
        },
      },
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        width: "30%",
        ellipsis: true,
        render: (value: string) => value,
      },
    ],
    [getLine]
  );

  return { columns };
};
