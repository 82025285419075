import { useCallback } from "react";

export const useTagLabel = () => {
  const getTagLabel = useCallback((item: any, value: string): string => {
    let name: string;

    if (item) {
      name = item.name;

      if (item.role) {
        if (name.length > 58) {
          name = name.substring(0, 57) + " ...";
        }

        name += ` | ${item.role === 1 ? "Buyer" : "Seller"}`;
      } else if (name.length > 58) {
        name = name.substring(0, 57) + " ...";
      }
    } else {
      name = value;
    }

    return name;
  }, []);

  return { getTagLabel };
};
