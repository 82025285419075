import { Form, Select } from "antd";
import { useMemo } from "react";
import { IOption } from "../../../../../../../../../../types/Option";

type YearsItemProps = {
  years: IOption[];
};

export const YearsItem = ({ years }: YearsItemProps) => {
  const options: IOption[] = useMemo(() => {
    return years.map((item: IOption) => ({
      label: item.value,
      value: item.value,
    }));
  }, [years]);

  return (
    <Form.Item label="Year" name="year" className="years-select mb-6">
      <Select options={options} />
    </Form.Item>
  );
};
