import { Col, Form, Input, Row, Select } from "antd";
import { useFetchData } from "../hooks/useFetchData";
import TextArea from "antd/es/input/TextArea";
import { Attachments } from "./Attachments";
import { SubjectRow } from "./SubjectRow";
import { Loader } from "../../../pages/Account/components/View/Admin/HeaderActions/components/EmailsModal/components/Loader";

type FormItemsProps = {
  id: string;
  sourceType: 0 | 1 | 2;
};

export const FormItems = ({ id, sourceType }: FormItemsProps) => {
  const { isLoading, data } = useFetchData(id, sourceType);

  return (
    <Row>
      {isLoading ? (
        <Loader />
      ) : (
        <Form
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          className="w-100 view-email-modal"
          initialValues={data}
        >
          <Row className="w-100">
            <Col span={20} className="pr-2">
              <Form.Item label="Sent to" name="email">
                <Select
                  mode="tags"
                  disabled={sourceType !== 1}
                  maxTagTextLength={45}
                />
              </Form.Item>
            </Col>
            <Col span={4} className="pl-2">
              <Form.Item label="Sent Date" name="date">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <SubjectRow sourceType={sourceType} />

          <Form.Item label="Message" name="body" className="mb-4">
            <TextArea rows={7} disabled={sourceType !== 1} />
          </Form.Item>

          <Attachments sourceType={sourceType} />
        </Form>
      )}
    </Row>
  );
};
