import { useCallback } from "react";

export const useFormData = (sendTo: any[], contractId: string) => {
  const getFormData = useCallback(
    (data: any) => {
      const formData = new FormData();

      for (const [key, value] of Object.entries(data)) {
        if (value === undefined || value === null) {
          continue;
        }

        if (key === "sendTo") {
          data.sendTo.forEach((item: string, i: number) => {
            const val = sendTo.find((data: any) => data.i.toString() === item);

            if (val) {
              val.id && formData.append(`Receivers[${i}][id]`, val.id);

              formData.append(`Receivers[${i}][email]`, val.email);
            }
          });

          continue;
        }

        if (key === "attachments") {
          const templateIds: string[] = [];
          const files: any[] = [];

          data.attachments.forEach((item: any) => {
            if (item.templateId) {
              templateIds.push(item.templateId);
            } else if (item.originFileObj) {
              files.push(item.originFileObj);
            }
          });

          templateIds.forEach((item: string, i: number) =>
            formData.append(`reportFileItemIds[${i}]`, item)
          );

          files.forEach((item: string) => formData.append(`files`, item));

          continue;
        }

        formData.append(key, data[key]);
      }

      formData.append("contractId", contractId);

      return formData;
    },
    [contractId, sendTo]
  );

  return { getFormData };
};
