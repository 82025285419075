import { useCallback } from "react";
import {
  transformToFloat,
  transformToFormatted,
} from "../../../lib/transformToFloat";

export const useTransformAmounts = (isRequest: boolean = true) => {
  const transform = useCallback(
    (data: any) => {
      [
        "amount",
        "interestAmount",
        "payeeFee",
        "payeeTax",
        "payerFee",
        "payerTax",
      ].forEach((prop: string) => {
        data[prop] = isRequest
          ? transformToFloat(data[prop])
          : transformToFormatted(data[prop]) || null;
      });

      const { breakdown } = data;

      for (let [key] of Object.entries(breakdown)) {
        if (key !== "lateCharge") {
          breakdown[key] = isRequest
            ? transformToFloat(breakdown[key])
            : transformToFormatted(breakdown[key]) || null;
        }
      }

      let { lateCharge } = breakdown;

      if (lateCharge) {
        for (let [key] of Object.entries(lateCharge)) {
          if (key !== "assessMethod") {
            lateCharge[key] = isRequest
              ? transformToFloat(lateCharge[key])
              : transformToFormatted(lateCharge[key]) || null;
          }
        }
      } else {
        lateCharge = {
          assessed: null,
          assessMethod: 2,
        };
      }

      return {
        ...data,
        breakdown: {
          ...breakdown,
          lateCharge,
        },
      };
    },
    [isRequest]
  );

  return { transform };
};
