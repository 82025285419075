import { Button, Form, FormInstance, Row, Upload } from "antd";
import { useCallback, useMemo } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useBeforeUpload } from "../../../../lib/hooks/useBeforeUpload";
import { useDownload } from "../../../../pages/Administration/TemplatesTab/hooks/useDownload";
import MailingsService from "../../../../services/accounts/mailings";
import ReportService from "../../../../services/reportGeneration";
import { useRules } from "./hooks/useRules";

type LetterProps = {
  form: FormInstance;
  disabled: boolean;
};

export const Letter = ({ form, disabled }: LetterProps) => {
  const { rules } = useRules();

  const { onDownload } = useDownload(
    disabled ? MailingsService : ReportService
  );

  const onDownlaodClick = useCallback(
    (file: any) => onDownload(file.id, file.name),
    [onDownload]
  );

  const templateId = Form.useWatch("templateId", form);

  const { beforeUpload } = useBeforeUpload();

  const showUploadListProps = useMemo(
    () => ({ showDownloadIcon: true, showRemoveIcon: !disabled }),
    [disabled]
  );

  return (
    <Row className="position-relative">
      <Form.Item
        label={disabled || templateId ? "Letter" : ""}
        name="fileList"
        className="mb-2 w-100"
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
        rules={rules}
      >
        <Upload
          listType="picture"
          beforeUpload={beforeUpload}
          previewFile={async () => ""}
          showUploadList={showUploadListProps}
          onDownload={onDownlaodClick}
          maxCount={1}
        >
          {templateId && (
            <>
              <Button icon={<UploadOutlined />} shape="round">
                Replace File
              </Button>
              <span className="ml-2 text-sm title">
                Download, edit and replace the file to edit the letter
              </span>
            </>
          )}
        </Upload>
      </Form.Item>
    </Row>
  );
};
