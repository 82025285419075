import { Button, Row, Tooltip } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import { usePrintEnvelope } from "../../../../hooks/usePrintEnvelope";

type PrintLabelProps = {
  customerId: string;
  propName: string;
};

export const PrintLabel = ({ customerId, propName }: PrintLabelProps) => {
  const contanerStyle = useMemo(() => ({ marginBottom: 2 }), []);

  const addressType: 1 | 2 = useMemo(
    () => (propName === "primaryAddress" ? 1 : 2),
    [propName]
  );

  const { isLoading, print } = usePrintEnvelope(customerId, addressType);

  return (
    <Row style={contanerStyle}>
      <Tooltip title="Print Envelope" className="tooltip-label">
        <Button
          icon={<PrinterOutlined />}
          type="text"
          className="py-0 icon-btn pl-2"
          disabled={isLoading}
          onClick={print}
        />
      </Tooltip>
    </Row>
  );
};
