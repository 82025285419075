import { useEffect, useState } from "react";
import PaymentHistoryService from "../../../../../../services/accounts/paymentHistory";
import dayjs from "dayjs";
import { IOption } from "../../../../../../types/Option";

export const useFetchYears = (
  customerId: string,
  setRequest: React.Dispatch<React.SetStateAction<any>>
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [years, setYears] = useState<IOption[]>([]);

  useEffect(() => {
    PaymentHistoryService.fetchYearsByCustomer({ customerId })
      .then((res: number[]) => {
        if (res[0] !== 0) {
          const options: IOption[] = res.map((item: number) => ({
            value: item,
            label: item.toString(),
          }));

          const currentYear: number = dayjs().year();

          setYears(
            options.length > 0
              ? options
              : [{ value: currentYear, label: currentYear.toString() }]
          );

          setRequest((prev: any) => ({
            ...prev,
            year:
              options.length > 0
                ? options[options.length - 1].value
                : currentYear,
          }));

          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  }, [customerId, setRequest]);

  return { years, isLoading };
};
