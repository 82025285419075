import { Card, RadioChangeEvent, Row } from "antd";
import { useCallback, useState } from "react";
import { PaymentRecord } from "./components/PaymentRecord";
import { Payments } from "./components/Payments";
import { Totals } from "./components/Totals";
import { Loader } from "../../../../../../components/Loader";
import { Header } from "./components/Header";
import "./styles.scss";

type TPaymentHistoryProps = {
  contractId: string;
  accountNumber: number;
};

export const PaymentHistory = ({
  contractId,
  accountNumber,
}: TPaymentHistoryProps) => {
  const [isTotalsOpen, setIsTotalsOpen] = useState<boolean>(false);

  const [paymentId, setPaymentId] = useState<string | null | undefined>(
    undefined
  );

  const [shouldDataReload, setShouldDataReload] = useState<boolean>(false);

  const [openEditMode, setOpenEditMode] = useState<boolean>(false);

  const [selectValue, setSelectValue] = useState<number | undefined>(undefined);

  const [isActionsDisabled, setIsActionsDisabled] = useState<boolean>(false);

  const onAddBtnClick = useCallback(() => {
    setPaymentId(null);
    setOpenEditMode(true);
  }, []);

  const onRadioChange = useCallback((e: RadioChangeEvent) => {
    setIsTotalsOpen(e.target.value === 2);
  }, []);

  const back = useCallback(() => setPaymentId(undefined), []);

  const reloadPaymentsData = useCallback(() => {
    setPaymentId(undefined);
    setShouldDataReload(true);
  }, []);

  return (
    <Row className="w-100 py-6 px-8 payment-history-wrapper">
      <Card className="w-100">
        <Header
          contractId={contractId}
          isActionsDisabled={isActionsDisabled}
          isTotalsOpen={isTotalsOpen}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          onRadioChange={onRadioChange}
          onAdd={onAddBtnClick}
        />
        <Row className="w-100 pt-6 d-block table__container">
          {isTotalsOpen ? (
            <Totals />
          ) : selectValue !== undefined ? (
            <Payments
              accountId={contractId}
              setPaymentId={setPaymentId}
              shouldDataReload={shouldDataReload}
              year={selectValue}
              setShouldDataReload={setShouldDataReload}
              setOpenEditMode={setOpenEditMode}
              setIsActionsDisabled={setIsActionsDisabled}
              isActionsDisabled={isActionsDisabled}
            />
          ) : (
            <Loader fontSize="64px" />
          )}
        </Row>
      </Card>
      {paymentId !== undefined && (
        <PaymentRecord
          accountNumber={accountNumber}
          back={back}
          paymentId={paymentId}
          reloadData={reloadPaymentsData}
          contractId={contractId}
          openEditMode={openEditMode}
          setOpenEditMode={setOpenEditMode}
        />
      )}
    </Row>
  );
};
