import { useCallback } from "react";
import EmailsService from "../../../services/emails";
import { useOpenProgressModal } from "../../../lib/hooks/useOpenProgressModal";

export const useSendAll = (
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  reloadData: () => void
) => {
  const { openModal } = useOpenProgressModal(
    "Sending Emails",
    "Sending Emails failed",
    reloadData,
    "Emails were sent"
  );

  const sendAll = useCallback(async () => {
    try {
      setIsDisabled(true);

      const id: string = await EmailsService.sendAll();

      openModal(id);

      setIsDisabled(false);
    } catch {
      setIsDisabled(false);
    }
  }, [setIsDisabled, openModal]);

  return { sendAll };
};
