import { useMemo } from "react";
import { IOption } from "../../../../../../../../../../types/Option";
import { IPayersGet } from "../../../../../../../../../../types/Payer";
import { Checkbox, Form } from "antd";

type PayersListProps = {
  data: IPayersGet[];
};

export const PayersList = ({ data }: PayersListProps) => {
  const options: IOption[] = useMemo(
    () =>
      data.map((item: IPayersGet) => ({
        value: item.id,
        label: item.payerName,
      })),
    [data]
  );

  return (
    <Form.Item label="Payer" name="payerIds" className="payers-list mb-0">
      <Checkbox.Group options={options} />
    </Form.Item>
  );
};
