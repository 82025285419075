import { useEffect, useState } from "react";
import ContractsService from "../../../services/accounts/contracts";
import _ from "lodash";
import {
  ACCOUNT_NOT_FOUND,
  ACCOUNT_NUMBER_REQUIRED,
  WRONG_ACCOUNT_NUMBER,
} from "../../../lib/constants";
import BatchPaymentsService from "../../../services/processing/payments";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { PaymentManualUpdated } from "../../../types/PaymentManualUpdated";
import { useTransformAmounts } from "./useTransformAmounts";

export const useInitPayment = (
  paymentId: string,
  batchId: string,
  setManualUpdated: React.Dispatch<React.SetStateAction<PaymentManualUpdated>>
) => {
  const [initialData, setInitialData] = useState<any>(null);

  const navigate: NavigateFunction = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [accountInfo, setAccountInfo] = useState<any>(null);

  const { transform } = useTransformAmounts(false);

  const init = async (
    value: string | undefined,
    setError: (value: string) => void
  ) => {
    if (value && !_.isNaN(+value)) {
      setIsLoading(true);

      setInitialData(null);

      try {
        const contract = await ContractsService.searchByNumber(+value);
        setAccountInfo(contract);

        const request = {
          batchId,
          contractId: contract.id,
        };

        const data = await BatchPaymentsService.initialize(request);

        const entity = {
          payment: { ...transform(data.payment) },
          warnings: [...data.warnings],
        };

        setInitialData(entity);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);

        error.response.data.title === "Item not found." &&
          setError(ACCOUNT_NOT_FOUND);
        setIsLoading(false);
      }
    } else {
      setError(value ? WRONG_ACCOUNT_NUMBER : ACCOUNT_NUMBER_REQUIRED);
    }
  };

  useEffect(() => {
    if (paymentId !== "create") {
      setIsLoading(true);

      BatchPaymentsService.fetch(paymentId)
        .then((res) => {
          const data = { payment: transform({ ...res }), warnings: [] };
          setInitialData(data);
          setIsLoading(false);
          setAccountInfo({ id: res.contractId });
          setManualUpdated(res.manualUpdated);
        })
        .catch((e) => {
          e.response.data.status === 400 && navigate("/not-found");
          setIsLoading(false);
        });
    }
  }, [navigate, paymentId, setManualUpdated, transform]);

  return { init, isLoading, initialData, accountInfo, setInitialData };
};
