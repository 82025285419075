import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";

export const Message = () => {
  return (
    <Form.Item label="Message" name="body" className="mb-4">
      <TextArea rows={7} />
    </Form.Item>
  );
};
