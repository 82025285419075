import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import { useMemo } from "react";
import { useFetchTemplates } from "./hooks/useFetchTemplates";
import { useUpdateAttachments } from "../Attachments/hooks/useUpdateAttachments";

type SubjectRowProps = {
  form: FormInstance;
  isLoading: boolean;
  contractId: string;
  setGlobalIsLoading: (value: boolean) => void;
  clearError: () => void;
};

export const SubjectRow = ({
  form,
  isLoading,
  contractId,
  setGlobalIsLoading,
  clearError,
}: SubjectRowProps) => {
  const subjectRules = useMemo(
    () => [
      {
        required: true,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  const { isLoading: isTemplatesLoading, formatted } = useFetchTemplates();

  useUpdateAttachments(form, contractId, setGlobalIsLoading);

  return (
    <Row className="w-100 align-start">
      <Col span={15} className="pr-2">
        <Form.Item
          className="mb-4"
          label="Email Subject"
          name="subject"
          rules={subjectRules}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={9}>
        <Form.Item className="mb-4 mt-6" name="templateId">
          <Select
            options={formatted}
            disabled={isTemplatesLoading || isLoading}
            placeholder="Insert from Template"
            onChange={clearError}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
