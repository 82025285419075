import { Alert, Row } from "antd";
import { ReactNode, useEffect, useMemo } from "react";
import { useScrollToDiv } from "../../../../../../../../../lib/hooks/useScrollToDiv";

type ErrorContainerProps = {
  error: any;
};

export const ErrorContainer = ({ error }: ErrorContainerProps) => {
  const { ref, scrollToDiv } = useScrollToDiv();

  const message: ReactNode = useMemo(() => {
    const { additionalData } = error;

    if (additionalData) {
      const err = Object.keys(error.failedItems);

      const succeded = additionalData.succesfulIySentTo.split(",");

      return (
        <Row className="d-block">
          <Row className="d-block mb-2">
            Emails for some recievers were not delivered:
          </Row>
          <ul className="my-0">
            {err.map((item: string, i: number) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
          {succeded.length > 0 && (
            <>
              <Row className="d-bloc my-2">Delivered emails:</Row>
              <ul className="my-0">
                {succeded.map((item: string, i: number) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </>
          )}
        </Row>
      );
    } else {
      return <Row className="d-block">{error.response.data.title}</Row>;
    }
  }, [error]);

  useEffect(() => scrollToDiv(), [message, ref, scrollToDiv]);

  return (
    <Row ref={ref} className="d-block">
      <Alert message={message} showIcon type="error" className="mb-4" />
    </Row>
  );
};
