import { Button, Dropdown, MenuProps, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useCallback, useMemo, useState } from "react";
import { LetterModal } from "../../../../../../../../components/LetterModal";
import { IPrimaryData } from "../../types/PrimaryData";

type PrintProps = {
  contractId: string;
  isLoading: boolean;
  primaryData: IPrimaryData;
};

export const Print = ({ contractId, isLoading, primaryData }: PrintProps) => {
  const [letterId, setLetterId] = useState<string | null | undefined>(
    undefined
  );

  const openPrintLetter = useCallback(() => setLetterId(null), []);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: 1,
        label: (
          <Row id="1" onClick={openPrintLetter}>
            Letter from Template
          </Row>
        ),
      },
    ],
    [openPrintLetter]
  );

  const close = useCallback(() => setLetterId(undefined), []);

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]} disabled={isLoading}>
        <Button shape="round" className="d-inline-flex align-center ml-4">
          Print <DownOutlined />
        </Button>
      </Dropdown>

      <LetterModal
        id={letterId}
        contractId={contractId}
        primaryData={primaryData}
        close={close}
        shouldOpen={letterId !== undefined}
      />
    </>
  );
};
