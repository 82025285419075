import { Button, Form, FormInstance, Row, Tooltip } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useCallback, useMemo } from "react";

type AddressLabelProps = {
  propName: string;
  form: FormInstance;
};

export const AddressLabel = ({ propName, form }: AddressLabelProps) => {
  const isReturned = Form.useWatch([propName, "isInactual"], {
    form,
    preserve: true,
  });

  const buttonClass: string = useMemo(() => {
    return `py-0 icon-btn pl-2 ${isReturned ? "marked" : ""}`;
  }, [isReturned]);

  const tooltipText: string = useMemo(() => {
    return !isReturned
      ? "Mark as a returned mail"
      : "Unmark as a returned mail";
  }, [isReturned]);

  const toggleIsReturned = useCallback(() => {
    form.setFieldValue([propName, "isInactual"], !isReturned);
  }, [form, isReturned, propName]);

  return (
    <Form.Item name={[propName, "isInactual"]} className="mb-0 toggle-mail">
      <Row>
        Address
        <Tooltip title={tooltipText} className="tooltip-label">
          <Button
            icon={<MailOutlined />}
            type="text"
            className={buttonClass}
            onClick={toggleIsReturned}
          />
        </Tooltip>
      </Row>
    </Form.Item>
  );
};
