import { ColumnsType } from "antd/es/table";
import dayjs from "../../../../../../../configs/dayjs";
import { useCallback, useMemo } from "react";
import { DATE_FORMAT } from "../../../../../../../lib/constants";
import { Dropdown, MenuProps, Row, Tooltip } from "antd";
import {
  MailOutlined,
  EllipsisOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useFilters } from "./useFilters";
import { useDefaultDeleteModal } from "../../../../../../../lib/hooks/defaultDeleteModal";
import { deleteLetter } from "../../../../../../../acions/accounts/mailings";

export const useTableColumns = (
  setItemToOpen: React.Dispatch<
    React.SetStateAction<{
      id: string;
      type: 1 | 2;
    } | null>
  >,
  reloadData: () => void
) => {
  const { filters } = useFilters();

  const { openDeleteModal } = useDefaultDeleteModal(deleteLetter, reloadData);

  const onView = useCallback(
    (id: string, type: 1 | 2) => setItemToOpen({ id, type }),
    [setItemToOpen]
  );

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      openDeleteModal(e.currentTarget.id, "Delete Letter");
    },
    [openDeleteModal]
  );

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Sent Date",
        dataIndex: "date",
        key: "date",
        width: "15%",
        render: (value: string) => dayjs(value).format(DATE_FORMAT),
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        width: "45%",
        ellipsis: true,
        render: (value: string, record: any) => {
          const { type } = record;

          return (
            <Row className="w-100">
              <Tooltip placement="top" title={type === 1 ? "Email" : "Letter"}>
                {type === 1 ? (
                  <MailOutlined className="mr-3" />
                ) : (
                  <PrinterOutlined className="mr-3" />
                )}
              </Tooltip>
              <p className="my-0 elipsis">{value}</p>
            </Row>
          );
        },
      },
      {
        title: "Receiver",
        dataIndex: "receiverName",
        key: "receiverName",
        width: "33%",
        ellipsis: true,
        render: (value: string, record: any) => {
          const { email } = record;

          return (
            <Row className="d-block w-100">
              <p className="my-0 text-md elipsis">{value}</p>
              <p className="my-0 text-sm title elipsis">{email}</p>
            </Row>
          );
        },
      },
      {
        key: "receiverName",
        width: "7%",
        filters,
        align: "right",
        render: (record: any) => {
          const { id, type } = record;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: <Row onClick={() => onView(id, type)}>View</Row>,
            },
          ];

          type === 2 &&
            items.push({
              key: "2",
              label: (
                <Row id={id} onClick={onDelete}>
                  Delete
                </Row>
              ),
            });

          return (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
            </Dropdown>
          );
        },
      },
    ],
    [filters, onView, onDelete]
  );

  return { columns };
};
