import { FormInstance } from "antd";
import { Dayjs } from "dayjs";
import { useCallback, useState } from "react";
import { DATE_FORMAT } from "../../../lib/constants";
import { useOpenProgressModal } from "../../../lib/hooks/useOpenProgressModal";
import EmailsService from "../../../services/emails";

export const useCreateEmails = (
  form: FormInstance,
  batchDateInit: Dayjs,
  setIsModalOpen: (val: boolean) => void,
  reloadData: () => void
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [batchDateError, setBatchDateError] = useState<string>("");

  const { openModal } = useOpenProgressModal(
    "Creating Emails",
    "Creating Emails failed",
    reloadData,
    "Emails were created"
  );

  const checkBatchDate = useCallback(
    (batchDate: Dayjs): boolean => {
      if (batchDate.startOf("D").diff(batchDateInit.startOf("D")) > 0) {
        setBatchDateError(
          `Batch Date must be less than ${batchDateInit.format(DATE_FORMAT)}`
        );
        return false;
      }

      return true;
    },
    [batchDateInit]
  );

  const create = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await form.validateFields();

      const { batchDate } = res;

      if (checkBatchDate(batchDate)) {
        const request = {
          ...res,
          batchDate: res.batchDate.format(),
        };

        const id: string = await EmailsService.create(request);

        openModal(id);

        setIsModalOpen(false);
      }

      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [checkBatchDate, form, openModal, setIsModalOpen]);

  return { isLoading, create, batchDateError, setBatchDateError };
};
